import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import FeedItem from '../FeedItem/FeedItem';
import Avatar from 'react-avatar';
import { BaseItem } from "../../services/HomePage.model";
import { getHomePageItemDetail, getHomePageTrendingItemDetail, getPortfolioInfo, getQuestionInfo, getTopicInfo } from '../../services/RepoService';
import { getDailyTrendingDetailsTopic, getDailyTrendingDetailsQuestion, getHashtags, getHashtagDetails } from "../../services/HomePage.service";
import { FeedItemType } from '../../model/BaseResource.model';
import DailyTrendingItem from "../DailyTrendingItem/DailyTrendingItem";
import { InstagramEmbed, FacebookEmbed, YouTubeEmbed, TwitterEmbed, TikTokEmbed, LinkedInEmbed } from 'react-social-media-embed';
import StocksUpDown from "../StocksUpDown/StocksUpDown";
import "./StandaloneItem.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { url } from 'inspector';
import { getBaseHref } from "../../url-base-href-ts";
import TimeLine from '../TimeLine/TimeLine';
const BASE_HREF = getBaseHref();

function StandaloneItem({ state, setState }: any) {
    let { id, type } = useParams<{ id: string, type: string }>()
    const [item, setItem] = useState(undefined);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [dailyTrendItemsTopic, setDailyTrendItemsTopic] = useState({ Topic: [] })
    const [dailyTrendItemsQuestion, setDailyTrendItemsQuestion] = useState({ Question: [] })
    const [trends, setTrends] = useState([]);
    const [hashType, setHashType] = useState(false);
    const [hashDetails, setHashDetails] = useState([]);
    const location = useLocation();
    const locationItem = location?.state?.['item'];
    const initialDocumentTitle = document.title;

    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    useEffect(() => {
        (async () => {
            try {
                type = type.toLowerCase();
                let item = undefined; // type == FeedItemType.PORTFOLIO.toLowerCase() ? locationItem : undefined;
                if (!item) {
                    // switch (type.toLowerCase()){
                    //     case FeedItemType.QUESTION.toLowerCase(): 
                    //         item = await getQuestionInfo(id);
                    //         break;

                    //     case FeedItemType.PORTFOLIO.toLowerCase(): 
                    //         item = await getPortfolioInfo(+id);
                    //         break;

                    //     case FeedItemType.TOPIC.toLowerCase():
                    //         item = await getTopicInfo(id);
                    //         break;
                    // }
                    if (type != "hashtag") {
                        if (window.location.pathname.indexOf("/trending/") > 1) {
                            item = await getHomePageTrendingItemDetail(+id);
                        }
                        else {
                            item = await getHomePageItemDetail(+id);
                        }
                        setItem(item);
                        setDataLoaded(true);
                        document.title += ` - ${item.title}`
                    }
                    else {
                        var hrefParts = window.location.pathname.split('/');
                        var hash = hrefParts[hrefParts.length - 1];
                        item = await getHashtagDetails(hash);
                        setHashDetails(item);
                        setHashType(true);
                        item.type = hash;
                        setDataLoaded(true);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [])

    useEffect(() => {
        (async () => {
            try {
                const dailyTrendingItemsTopic = await getDailyTrendingDetailsTopic();
                await setDailyTrendItemsTopic(dailyTrendingItemsTopic);
            } catch (e) {
                console.error(e);
            }
        })();
    }, [])

    useEffect(() => {
        (async () => {
            try {
                const dailyTrendingItemsQuestion = await getDailyTrendingDetailsQuestion();
                await setDailyTrendItemsQuestion(dailyTrendingItemsQuestion);

                const trendz = await getHashtags();
                setTrends(trendz);

            } catch (e) {
                console.error(e);
            }
        })();
    }, [])

    useEffect(() => {
        return () => {
            document.title = initialDocumentTitle;
        }
    }, []);

    const updateItem = (newItem) => {
        setItem(newItem);
    }
    const rowLen = hashDetails.length;
    return (
        <>
            <div className="d-block d-lg-none">
                <>
                    {hashType && <>
                        <h5>{'#' + hashDetails[0].hashtag}</h5>
                        <br />
                        <h5 style={{ color: 'green' }}>{hashDetails[0].title}</h5>
                        {/* <div className="d-flex align-items-center mb-2">
                             <img alt={item.postedby} className="sj-user-avatar" src={`${process.env.PUBLIC_URL}/images/profile-xs.png`}/>
                            <Avatar
                                size={"40"} round={true} textSizeRatio={1} 
                                // name={item.postedby}
                                maxInitials={2}
                            ></Avatar>
                            <div className="d-inline-flex flex-column ms-2">
                                <span className="mx-1">
                                 {item.postedby} 
                                </span>
                                <span
                                    className="mx-1 small"
                                    title={(new Date(item.postedate).toString())}>
                                    {item.timedifference == "0" ? 'Just now' : item.timedifference || 'now'}
                                </span>
                            </div>
                        </div> */}
                        {hashDetails[0].image_url && <img src={BASE_HREF + hashDetails[0].image_url.replace('\sj','')} className="img-fluid" width="100%" />}
                       
                        {hashDetails[0].gif_url && <img src={hashDetails[0].gif_url} className="img-fluid" width="100%" />}

                        {hashDetails[0].social_url && (hashDetails[0].social_url.includes('facebook') ?
                            <FacebookEmbed url={hashDetails[0].social_url} /> :
                            (hashDetails[0].social_url.includes('instagram') ?
                                <InstagramEmbed url={hashDetails[0].social_url} /> :
                                (hashDetails[0].social_url.includes('youtube') ?
                                    <div style={{ zoom: '60%' }}> <YouTubeEmbed url={hashDetails[0].social_url} /></div> :
                                    ((hashDetails[0].social_url.includes('twitter') || (hashDetails[0].social_url.includes('x.com'))) ?
                                        <TwitterEmbed url={hashDetails[0].social_url} /> :
                                        (hashDetails[0].social_url.includes('tiktok') ?
                                            <TikTokEmbed url={hashDetails[0].social_url} /> :
                                            (hashDetails[0].social_url.includes('linked') ?
                                                <LinkedInEmbed url={hashDetails[0].social_url} /> : ''
                                            )
                                        )
                                    )
                                )
                            )
                        )
                        }
                        {renderHTML(hashDetails[0].summary)}
                        {hashDetails && <div style={{ backgroundColor: 'black' }}>
                            <p style={{ textAlign: 'left', color: 'white', paddingLeft: "10px" }}> <b> Timeline</b></p>
                        </div>}
                        {
                            hashDetails.map((item, index) => {
                                return rowLen === index + 1 ?
                                    <TimeLine state={state} showArrow="none" setState={setState} item={item} />
                                    :
                                    <TimeLine state={state} showArrow="block" setState={setState} item={item} />
                            }
                            )
                        }
                    </>
                    }
                    {(dataLoaded && !hashType) &&
                        <FeedItem
                            state={state}
                            setState={setState}
                            item={item}
                            standaloneView={true}
                            updateItem={updateItem}
                        ></FeedItem>
                    }
                    {!dataLoaded && <h3>Loading...</h3>}
                </>
            </div>


            <div className="d-none d-md-block">
                <div className="sj-feed-container mx-auto">
                    <div className="row">
                        <div className="col-lg-3 col-12 sj-feed-item px-2">
                            <div className="d-flex">
                                <img src={process.env.PUBLIC_URL + `/images/trending-topic.png`} height="20" />
                                <h5 style={{ color: 'purple', paddingLeft: '3px' }}>Trending News</h5>
                            </div>
                            {dailyTrendItemsTopic.Topic?.map((topic: BaseItem) => (
                                <div className="trending-item-wrapper">
                                    <DailyTrendingItem key={topic.id} item={topic} state={state} setState={setState} />
                                </div>
                            ))}
                        </div>
                        <div className="px-lg-5 col-12 col-lg-6 sj-feed-container">
                            {hashType && <> 
                                <h5>{'#' + hashDetails[0].hashtag}</h5>
                                <br />
                                <h5 style={{ color: 'green' }}>{hashDetails[0].title}</h5>
                               
                                {hashDetails[0].image_url && <img src={BASE_HREF + hashDetails[0].image_url.replace('\sj','')} className="img-fluid" width="100%" />}

                                {hashDetails[0].gif_url && <img src={hashDetails[0].gif_url} className="img-fluid" width="100%" />}

                                {hashDetails[0].social_url && (hashDetails[0].social_url.includes('facebook') ?
                                    <FacebookEmbed url={hashDetails[0].social_url} /> :
                                    (hashDetails[0].social_url.includes('instagram') ?
                                        <InstagramEmbed url={hashDetails[0].social_url} /> :
                                        (hashDetails[0].social_url.includes('youtube') ?
                                            <div style={{ zoom: '90%' }}> <YouTubeEmbed url={hashDetails[0].social_url} /></div> :
                                            ((hashDetails[0].social_url.includes('twitter') || (hashDetails[0].social_url.includes('x.com')))?
                                                <TwitterEmbed url={hashDetails[0].social_url} /> :
                                                (hashDetails[0].social_url.includes('tiktok') ?
                                                    <TikTokEmbed url={hashDetails[0].social_url} /> :
                                                    (hashDetails[0].social_url.includes('linked') ?
                                                        <LinkedInEmbed url={hashDetails[0].social_url} /> : ''
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                                }
                                {renderHTML(hashDetails[0].summary)}
                                {hashDetails && <div style={{ backgroundColor: 'black' }}>
                                    <p style={{ textAlign: 'left', color: 'white', paddingLeft: "10px" }}> <b> Timeline</b></p>
                                </div>}
                                {
                                     hashDetails.map((item, index) => {
                                        return rowLen === index + 1 ?
                                            <TimeLine state={state} showArrow="none" setState={setState} item={item} />
                                            :
                                            <TimeLine state={state} showArrow="block" setState={setState} item={item} />
                                    }
                                    )
                                }
                            </>
                            }
                            {(dataLoaded && !hashType) &&
                                <FeedItem
                                    state={state}
                                    setState={setState}
                                    item={item}
                                    standaloneView={true}
                                    updateItem={updateItem}
                                ></FeedItem>
                            }
                            {!dataLoaded && <h3>Loading...</h3>}
                        </div>
                        <div className="col-lg-3 sj-feed-item px-2">
                            <h5 style={{ color: "purple" }}>Trending Timelines</h5>
                            {
                                trends.filter(element => {
                                    return element;
                                }).map((trend) => <>
                                    <Link to={{ pathname: `item/hashtag/Trending/${trend}`, state: { trend } }} target="_blank" className="title-link">
                                        <h6 className="fw-bold mt-1">
                                            #{trend}
                                        </h6>
                                    </Link>
                                </>
                                )
                            }
                            <br />
                            <h5 style={{ color: "purple" }}>Trending Stocks</h5>
                            {dailyTrendItemsQuestion.Question?.map((question: BaseItem) => (
                                <div className="trending-item-wrapper">
                                    <StocksUpDown key={question.id} item={question} state={state} setState={setState} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StandaloneItem
