import React, { useEffect, useState } from 'react';
import { kebabCase } from 'lodash';
import { BaseProp, FeedItemType } from '../../model/BaseResource.model'
import { Link } from 'react-router-dom';
import { getBaseHref } from "../../url-base-href-ts";
import { InstagramEmbed, FacebookEmbed, YouTubeEmbed, TwitterEmbed, TikTokEmbed, LinkedInEmbed } from 'react-social-media-embed';
import "../FeedItem/FeedItem.scss"; 

// const BASE_HREF = 'https://api.stockjabber.com:8443/dev/sj';
const BASE_HREF = getBaseHref();

export interface TimeLineProps extends BaseProp {
    showArrow :any
}

function TimeLine({ item,showArrow, state }: TimeLineProps) {
    const itemLink = `item/${item.type}/${item.id}/trending/${kebabCase(item.title)}`.toLocaleLowerCase();
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    
    var impact = item?.impact?.split(',')
    
    return (
        <div className="d-flex pb-1 pl-1">
            <div className="mx-1" id="revanthImmadi">
                <div className="d-flex flex-column" style={{ border: 'thin solid black', borderRadius:'15px', width:'100%', fontFamily:'Segoe UI', justifyContent:'center' , overflowWrap:'anywhere', padding:'15px' }}>
                    {item.postedate}
                    
                    <Link to={{ pathname: `/${itemLink}`, state: { item } }} style={{color : 'green !important'}} target="_blank" className="link-decoration">
                        <h6 className="fw-bold mt-1">
                            {item.title?.replace(/\n/g, ' ')}
                        </h6>
                    </Link>
                    {/* {item.summary?.length > 200 ?<> {renderHTML(item.summary.substring(0, 200))+'...'} </> : renderHTML(item.summary)} */}
                   <p style={{fontFamily:'Segoe UI !important'}}> {renderHTML(item.summary)} 

                   {/* {item.image_url && <img src={BASE_HREF + item.image_url.replace('\sj','')} className="img-fluid" width="100%" />}
                       {item.gif_url && <img src={item.gif_url} className="img-fluid" width="100%" />} */}
<br />
                       {item.social_url && (item.social_url.includes('facebook') ?
                           <FacebookEmbed url={item.social_url} /> :
                           (item.social_url.includes('instagram') ?
                               <InstagramEmbed url={item.social_url} /> :
                               (item.social_url.includes('youtube') ?
                                   <div style={{ zoom: '60%' }}> <YouTubeEmbed url={item.social_url} /></div> :
                                   (item.social_url.includes('twitter') ?
                                       <TwitterEmbed url={item.social_url} /> :
                                       (item.social_url.includes('tiktok') ?
                                           <TikTokEmbed url={item.social_url} /> :
                                           (item.social_url.includes('linked') ?
                                               <LinkedInEmbed url={item.social_url} /> : ''
                                           )
                                       )
                                   )
                               )
                           )
                       )
    }
                   <br />
                    <h6>Impact</h6>
                   {impact && <div style={{display:'inline-block'}}>
                   <span style={{ color: impact[0].includes("+") ? 'green' : (impact[0].includes("-") ? 'red': 'black') }}><b>{impact[0]}</b></span>
                  {impact[1] && <span style={{color: impact[1].includes("-") ? 'red' : (impact[1].includes("+") ? 'green':'black')}}><b>{impact[1]}</b></span>} </div>
                  }
                  </p>
                   {/* {item.impact} */}
                </div>  
                {/* <i className="bi bi-arrow-down"></i> */}
                <div style={{textAlign:'center', display:showArrow}}>
                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/downArrow.png`} />
                </div>
            </div>
        </div>
    )
}

export default TimeLine;
