import { Session } from 'inspector';
import React, { useState, useRef } from 'react'
import { FloatingLabel, Form, FormControl, FormGroup, InputGroup } from 'react-bootstrap'
import { Redirect, useHistory } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { SessionService } from '../../services/Session.service';

function Signup({ state, setState }: any) {
    const { session } = state;
    const nav = useHistory();
    const ref = useRef(null);
    const [signup, setSignup] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        emailId: '',
        password: '',
        confirmPassword: ''
    })

    const doSignup = async (event) => {
        event.preventDefault();
        try {
            if (!ref.current.checked) {
                alert(`Please accept the Terms and Private Policy`);
                return;
            }

            if (signup.phoneNumber?.length != 10) {
                alert(`Phone number is not valid`);
                return;
            }

            if (signup.emailId != "") {
                var regexp = /\S+@\S+\.\S+/;
                if (!regexp.test(String(signup.emailId).toLowerCase())) {
                    alert(`Email id is not valid`);
                    return;
                }
            }

            if (!(signup.firstName && signup.lastName && signup.phoneNumber?.length == 10 && signup.emailId && signup.password && signup.confirmPassword)) {
                alert(`Please enter all fields`);
                return;
            }
            if (signup.password != signup.confirmPassword) {
                alert('Password and Confirm Passwords are not same');
                return;
            }

            await (session as SessionService).createUser(signup);
            const user = await (session as SessionService).postLogin({ emailId: signup.emailId, password: signup.password });
            session.user = user;
            setState({ ...state, session });

            const loginRedirect = sessionStorage.getItem(`redirect_after_login`);
            alert('Success. You will be redirected to login');
            
            if (loginRedirect) {
                sessionStorage.removeItem(`redirect_after_login`);
                nav.push(loginRedirect);
            }
            else {
                // alert('No login');
                // after signup it is creating here -- ask pranav what is this mechanism and why it is needed?
                nav.push('/');
            }
        window.location.reload();
        } catch (e) {
            console.error(e);
            if(e.message == 'Error: 409, Message: Invalid Email / password.'){
                alert("Looks like the entered details were already exists..Try new Email");
            }
        }
    }

    return (
        <>
            {/* {session?.user && <Redirect to="/" />} */}
            <div className="md-5">
                <div className="row">
                    <div className="col-md-6 ">
                        <Form>
                            <FormGroup className="mb-3">
                                <FloatingLabel controlId="fname" label="First Name" className="mb-3">
                                    <Form.Control type="text" placeholder="First Name" required onChange={(event) => (setSignup({ ...signup, firstName: event.target.value }))} />
                                </FloatingLabel>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className="col-md-6 border-md-start">
                        <Form>
                            <FormGroup className="mb-3">
                                <FloatingLabel controlId="lname" label="Last Name" className="mb-3">
                                    <Form.Control type="text" placeholder="Last Name" required onChange={(event) => (setSignup({ ...signup, lastName: event.target.value }))} />
                                </FloatingLabel>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 ">
                        <Form>
                            <FormGroup className="mb-3">
                                <FloatingLabel controlId="phone" label="Phone Number" className="mb-3">
                                    <Form.Control type="text" maxLength={10} placeholder="Phone Number" minLength={10} pattern="\d{1,10}" onChange={(event) => (setSignup({ ...signup, phoneNumber: event.target.value }))} />
                                </FloatingLabel>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className="col-md-6 border-md-start">
                        <Form>
                            <FormGroup className="mb-3">
                                <FloatingLabel controlId="email" label="Email" className="mb-3">
                                    <Form.Control type="email" placeholder="Email" required onChange={(event) => (setSignup({ ...signup, emailId: event.target.value }))} />
                                </FloatingLabel>
                            </FormGroup>
                        </Form>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 ">
                        <Form>
                            <FormGroup className="mb-3">
                                <FloatingLabel controlId="password" label="Password" className="mb-3">
                                    <Form.Control type="password" placeholder="Password" required onChange={(event) => (setSignup({ ...signup, password: event.target.value }))} />
                                </FloatingLabel>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className="col-md-6 border-md-start">
                        <Form>
                            <FormGroup className="mb-3">
                                <FloatingLabel controlId="confirmPassword" label="Confirm Password" className="mb-3">
                                    <Form.Control type="password" placeholder="Confirm Password" required onChange={(event) => (setSignup({ ...signup, confirmPassword: event.target.value }))} />
                                </FloatingLabel>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <input type="checkbox" ref={ref} id="acceptTermsSignup"></input>&nbsp;<a href={`${process.env.PUBLIC_URL}/privacy-policy`} target="_blank">Please read terms and private policy</a> <br /> <br />
                    <button className="btn btn-outline-primary" style={{ borderRadius: '15px' }} onClick={(event) => doSignup(event)}>
                        &nbsp;&nbsp;  Signup &nbsp;&nbsp;
                    </button>
                </div>
            </div>
        </>
    )
}

export default Signup
