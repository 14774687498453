import { post } from "./RepoService";

interface User {
    id: number;
    firstName: string;
    lastName: string;
    userId: string;
    phoneNumber: string;
    emailId: string;
    password: string | null,
    lastlogout: string | null;
    regfrom: any;
    location: string | null;
    tradertype:  string | null;
    tradingtype: string | null;
    experience:  string | null;
}

export class SessionService {
    constructor() {
        try {
            this._user = JSON.parse(localStorage.getItem('user'));
        } catch {}
    }

    _user: User = null;

    set user (newUser){
        this._user = newUser;
        localStorage.setItem('user', JSON.stringify(newUser));
    }

    get user() {
        return this._user;
    }

    get userFullname() {
        return this._user ? `${this._user.firstName} ${this._user.lastName}` : ''
    }

    get userInitials() {
        return this._user ? `${this._user.firstName[0]}${this._user.lastName[0]}` : ''
    }

    postLogin(user: { emailId: string, password: string }): Promise<User> {
        return post<any>(`/login`, user).then(response => response.result[0]);
    }

    createUser(user : Partial<User>): Promise<User> {
        return post<any>(`/createuser`, user).then(response => response.result[0]);
    }
}

export function redirectIfNotLoggedIn(session, navigation) {
    if (!session.user) {
        if (window.confirm(`Please login to continue`)) {
            sessionStorage.setItem(`redirect_after_login`, navigation.location.pathname);
            navigation.push(`/login`);
            return false;
        }
    } else return true;
}