import { BaseProp, CommentKeyMap, CommentReplyKeyMap, FeedItemType, PortfolioReply, QuestionReply, TopicReply } from '../../model/BaseResource.model'
import Linkify from 'react-linkify';
import "./Comments.scss";
import NewComment from './NewComment';
import { useReducer, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { reverse as _reverse } from 'lodash';
import Avatar from 'react-avatar';

export interface CommentsProp extends BaseProp {
    comment: QuestionReply | PortfolioReply | TopicReply;
    type: FeedItemType;
    showNested?: boolean;
    isCommentReply?: boolean;
    commentLike?: Function;
    commentReplyLike?: Function;
    showNewComment?: boolean;
    showNewCommentPosition?: "top" | "bottom";
}

export default function Comments({ 
    comment, 
    type, 
    showNested, 
    isCommentReply, 
    state, 
    setState,
    showNewComment,
    showNewCommentPosition,
    commentLike,
    commentReplyLike,
    postReply,
    postReplyToReply,
    fullLink
 }: CommentsProp ) {
    const { session } = state;
    const [, forceUpdate] = useReducer(_ => _ + 1, 0);
    const nav = useHistory();

    const _dataSource = isCommentReply ? CommentReplyKeyMap : CommentKeyMap;
    const replyBy   = comment[_dataSource[type]['replyBy']];
    const replyDate = comment[_dataSource[type]['replyDate']];
    const likeCount = comment[_dataSource[type]['likeCount']];
    const replySummary = comment[_dataSource[type]['replySummary']]?.trim();
    
    const newCommentTop = showNewComment && showNewCommentPosition !== 'bottom';   // will default top to true... 
    const newCommentBottom = showNewComment && showNewCommentPosition === 'bottom';

    const [internalState, setInternalState] = useState({
        newCommentTop: showNewComment && showNewCommentPosition !== 'bottom',   // will default top to true... 
        newCommentBottom: showNewComment && showNewCommentPosition === 'bottom'
    })
    
    const toggleReplyComment = (override = !internalState.newCommentBottom) => {
        if (showNested){
            if(!session.user){
                if(window.confirm(`Please login to comment`)){
                    sessionStorage.setItem(`redirect_after_login`, nav.location.pathname);
                    nav.push(`/login`);
                }
            } else {
                const newBottom = override;
                setInternalState({ ...internalState, newCommentBottom: newBottom});
            }
        } else {
            nav.push(fullLink);
        }
    }

     
    
    return (
        <div className="sj-comment-reply">
            {
                (session?.user && newCommentTop) && <>
                    <NewComment 
                        state={state} 
                        setState={setState}
                        post={postReply}
                    ></NewComment>
                    <hr className="m-1" />
                </>
            }
            <div className="d-flex pb-1">
                <div className="mx-1 mt-2">
                    <Avatar size={"35"} round={true} textSizeRatio={1} name={replyBy} maxInitials={2}></Avatar>
                </div>
                <div className="mx-1">
                    <div className="d-flex mb-2 flex-column">
                        <div className="fw-bold">
                            {replyBy?.trim()}&nbsp;
                        </div>
                        <span
                            className="small" 
                            title={(new Date(replyDate).toString())}>
                            {comment.timedifference === "0" ? 'Just now' : comment.timedifference }
                        </span>
                    </div>
                    <p className="mb-1 pre-line">
                        <Linkify> 
                            { replySummary }
                        </Linkify>
                    </p>
                    {/* Only show action buttons is it's not a comment reply.. (2nd level comments cannot be liked or replied to ) */}
                    { !isCommentReply && <>
                        <div className="py-2 small">
                            <span className="mx-2" role="button" onClick={() => commentLike(comment)}>
                                <i className="far fa-thumbs-up"></i> { likeCount || 0 } { type === FeedItemType.QUESTION ? 'Helpful' : 'Agree' }
                            </span>
                            <span className="mx-5" role="button" onClick={() => toggleReplyComment()}>
                                <i className="far fa-comment-alt"></i> { comment.replyCommentcount || 0 } Comments
                            </span>
                        </div>
                    </>}
                </div>
            </div>
            {
                (session?.user && internalState.newCommentBottom) && <>
                    <div className="sj-comment-reply">
                        <NewComment 
                            state={state} 
                            setState={setState}
                            post={(summary) => {
                                toggleReplyComment(false);
                                postReplyToReply(comment, summary)
                            }}
                        ></NewComment>
                    </div>
                </>
            }
            { showNested && [...comment.reply || []].map(commentReply => (<Comments 
                state={state}
                setState={setState}
                key={commentReply.id} 
                comment={commentReply} 
                type={type} 
                showNested={true}
                isCommentReply={true}
                commentLike={commentLike}
                commentReplyLike={commentReplyLike}
                postReply={postReply}
                postReplyToReply={postReplyToReply}
                fullLink={fullLink}
            ></Comments>)) }
            
            {/* {
                !isCommentReply && <>
                    <div className="mx-5 my-2" role="button" onClick={() => toggleReplyComment()}>
                        <i className="far fa-comment-alt"></i> Add Comment
                    </div>
                </>
            } */}

            { !isCommentReply && <hr className="m-1" />}
        </div>
    )
}