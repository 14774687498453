import {BrowserRouter as Router, Switch, Route } from "react-router-dom";
// css
import 'bootstrap';         // importing regular vanila JS bootstrap 5
import './styles/App.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
// css end 
import Header from './components/Header/Header';
import MainFeed from "./components/MainFeed/MainFeed";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Privacy from "./components/Privacy/Privacy";
import StandaloneItem from "./components/StandaloneItem/StandaloneItem";
import Terms from "./components/Terms/Terms";
import Login from "./components/UserLogin/Login";
import Signup from "./components/UserLogin/Signup";
import Search from "./components/Search/Search";
import { SessionService } from "./services/Session.service";
import { useState, useEffect } from "react";
import NewPortfolio from "./components/NewPortfolio/NewPortfolio";
import USNews from "./components/USNews/USNews";
import USNewsMini from "./components/USNews/USNewsMini/USNewsMini";
import Profile from "./components/Profile/Profile";
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';

// Singleton for all Service / Classes
export const Injector = {
  session: new SessionService()
}

export default function App() {
  const [globalState, setGlobalState] = useState(Injector);
  const clientId="254675741531-om2q76vi5b4llcnqif7btlf7hehh7o71.apps.googleusercontent.com";

  useEffect(() => {
    const initClient = () => {
          gapi.client.init({
          clientId: clientId,
          scope: ''
        });
     };
     gapi.load('client:auth2', initClient);
 });

  return (
    <>
      <Router>
      <Header state={globalState} setState={setGlobalState} />
      <div className="p-3 pb-5 mb-5">
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/privacy-policy">
            <Privacy />
          </Route>
          <Route path="/new/portfolio">
            <NewPortfolio state={globalState} setState={setGlobalState} />
          </Route>
          <Route path="/terms-of-use">
            <Terms />
          </Route>
          <Route path="/item/:type/:id/:title">
            <StandaloneItem state={globalState} setState={setGlobalState} />
          </Route>
          <Route path="/news/:position">
            <USNewsMini state={globalState} setState={setGlobalState} fullWidth={true} />
          </Route>
          <Route path="/signup">
            <Signup state={globalState} setState={setGlobalState} />
          </Route>
          <Route path="/login">
            <Login state={globalState} setState={setGlobalState} />
          </Route>
          <Route path="/profile">
            <Profile state={globalState} setState={setGlobalState} />
          </Route>
          <Route path="/search">
            <Search state={globalState} setState={setGlobalState} />
          </Route>
          <Route path="/">
            <MainFeed state={globalState} setState={setGlobalState} />
          </Route>
        </Switch>
      </div>
      </Router>
    </>
  );
}