import { useEffect, useRef, useState } from "react";
import Button from 'react-bootstrap/Button';
import { Link, useHistory } from "react-router-dom";
import { FeedItemType } from "../../model/BaseResource.model";
import { BaseItem } from "../../services/HomePage.model";
import { getHomePageDetails, getTrendingDetails, getDailyTrendingDetailsTopic, getDailyTrendingDetailsQuestion, getHashtags } from "../../services/HomePage.service";
import { postQuestion, postTopic } from "../../services/RepoService";
import { redirectIfNotLoggedIn } from "../../services/Session.service";
import FeedItem from "../FeedItem/FeedItem";
import NewQuestion from "../NewQuestion/NewQuestion";
import NewTopic from "../NewTopic/NewTopic";
import TrendingItem from "../TrendingItem/TrendingItem";
import DailyTrendingItem from "../DailyTrendingItem/DailyTrendingItem";
import USNewsMini from "../USNews/USNewsMini/USNewsMini";
import Nav from 'react-bootstrap/Nav';
import HamburgerMenu from "../Header/Hamburger";
import StocksUpDown from "../StocksUpDown/StocksUpDown";
import './MainFeed.scss';
import axios from 'axios';
import { getBaseHref } from "../../url-base-href-ts";
import { Carousel } from 'react-responsive-carousel';
const BASE_HREF = getBaseHref();

enum ADD_ITEM {
    NONE = -1,
    TOPIC = 0,
    QUESTION = 1,
    PORTFOLIO = 2,
}

interface NewItemData {
    title: string;
    summary: string;
    gifUrl: string;
    imageUrl: string;
    socialUrl: string;
}

function MainFeed({ state, setState }: any) {
    const [feedItems, _setFeedItems] = useState<BaseItem[]>([]);
    const [pageNum, _setPageNum] = useState<number>(1);
    const [showAddItem, setShowAddItem] = useState(ADD_ITEM.NONE);
    const [trendItems, setTrendItems] = useState({ topics: [], questions: [] })
    const [dailyTrendItemsTopic, setDailyTrendItemsTopic] = useState({ Topic: [] })
    const [dailyTrendItemsQuestion, setDailyTrendItemsQuestion] = useState({ Question: [] })
    const [trends, setTrends] = useState([]);
    const [menu, setMenu] = useState(false);
    const feedItemsRef = useRef(feedItems);
    const pageNumRef = useRef(pageNum);
    const { session } = state;
    const nav = useHistory();
    const apple = /iPhone|iPad|iPod/.test(navigator.userAgent);
    const [deviceType, setDeviceType] = useState("");

    useEffect(() => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)
      ) {
        setDeviceType("Mobile");
      } else {
        setDeviceType("Desktop");
      }
    }, []);


    const setFeedItems = (data: BaseItem[]) => {
        feedItemsRef.current = data;
        _setFeedItems(data);
    }

    const setPageNum = (data: number) => {
        pageNumRef.current = data;
        _setPageNum(data);
    }

    useEffect(() => {
        (async () => {
            try {
                const items = await getHomePageDetails(pageNum);
                setFeedItems(items.result);
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const trendingItems = await getTrendingDetails();
                setTrendItems(trendingItems);
            } catch (e) {
                console.error(e);
            }
        })();
    }, [])

    useEffect(() => {
        (async () => {
            try {
                const dailyTrendingItemsTopic = await getDailyTrendingDetailsTopic();
                await setDailyTrendItemsTopic(dailyTrendingItemsTopic);
            } catch (e) {
                console.error(e);
            }
        })();   
    }, [])

    useEffect(() => {
        (async () => {
            try {
                const dailyTrendingItemsQuestion = await getDailyTrendingDetailsQuestion();
                await setDailyTrendItemsQuestion(dailyTrendingItemsQuestion);

                const trendz = await getHashtags();
                setTrends(trendz);

            } catch (e) {
                console.error(e);
            }
        })();
    }, [])

    useEffect(() => {
        let scrollLoaded = true;
        const detectPageScrollEnd = async () => {
            if (!scrollLoaded) return;
            if (window.innerHeight + window.scrollY >= (document.body.offsetHeight)) {
                scrollLoaded = false;
                await getNextFeedItems();
                scrollLoaded = true;
            }
        }
        window.addEventListener('scroll', detectPageScrollEnd);
        return () => window.removeEventListener('scroll', detectPageScrollEnd);
    }, []);

    const getNextFeedItems = async () => {
        const items = await getHomePageDetails(pageNumRef.current + 1);
        if (items.result.length === 0)
            return;

        setPageNum(pageNumRef.current + 1);
        const currentMap = {}
        feedItemsRef.current.map(item => currentMap[item.id] = true);
        setFeedItems([...feedItemsRef.current, ...items.result.filter(item => currentMap[item.id] === undefined)]);
    }

    const updateItem = (newItem) => {
        const index = feedItems.findIndex((_item) => {
            return _item.id == newItem.id;
        })
        if (index >= 0) {
            feedItems[index] = newItem;
        }
        setFeedItems([...feedItems])
    }

    function searchResults() {
        const key = document.getElementById('searchRevanth') as HTMLInputElement | null;

        // return window.location.href = process.env.PUBLIC_URL + "/search" +"?key="+ key.value;
        return window.open(process.env.PUBLIC_URL + "/search" + "?key=" + key.value);
    }

    const checkLogin = () => {
        try {
            return redirectIfNotLoggedIn(session, nav);
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    const submiQuestionTopic = async (data: NewItemData, type: FeedItemType) => {
        if (!data || !data.title || !data.summary)
            return alert(`Please enter all data`);

        let newItem;
        if (type === FeedItemType.QUESTION)
            newItem = await postQuestion(data.title, data.summary, session.user.id, data.gifUrl, data.imageUrl, data.socialUrl);
        else if (type === FeedItemType.TOPIC)
            newItem = await postTopic(data.title, data.summary, session.user.id, data.gifUrl, data.imageUrl, data.socialUrl);

        setFeedItems([newItem, ...feedItems]);
        setShowAddItem(ADD_ITEM.NONE);
    }

    return (
        <>
            <div className="d-none d-md-block">
                <div className="sj-feed-container mx-auto">
                    {/* https://codesandbox.io/s/o4bws?file=/src/sidebar.js:169-299 ==> Try this */}
                    <div className="row">
                        <div className="col-2">
                            <div style={{ float: 'left' }}>
                                <br />
                                <input type="search" placeholder="Search" name="search" id="searchRevanth" style={{ width: '80%' }} />
                                <button type="submit" onClick={() => searchResults()}><i className="fa fa-search"></i></button>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="new-item-options-wrapper border border-1">
                                <p className="text-muted ps-2 mb-0 add-item-help-text">Create Topic, Ask a Question, Get opinion on Portfolio</p>
                                <div className="row g-0 text-center new-item-options">
                                    <div className="col-4">
                                        <button className={`btn new-item topic ${showAddItem === ADD_ITEM.TOPIC ? 'active' : ''}`}
                                            onClick={() => checkLogin() && showAddItem !== ADD_ITEM.TOPIC ? setShowAddItem(ADD_ITEM.TOPIC) : setShowAddItem(ADD_ITEM.NONE)}
                                        >
                                            <i className="fas fa-bullhorn"></i>
                                            <span>Topic</span>
                                        </button>
                                    </div>
                                    <div className="col-4">
                                        <button className={`btn new-item question ${showAddItem === ADD_ITEM.QUESTION ? 'active' : ''}`}
                                            onClick={() => checkLogin() && showAddItem !== ADD_ITEM.QUESTION ? setShowAddItem(ADD_ITEM.QUESTION) : setShowAddItem(ADD_ITEM.NONE)}
                                        >
                                            <i className="fas fa-question-circle"></i>
                                            <span>Question</span>
                                        </button>
                                    </div>
                                    <div className="col-4">
                                        <button className={`btn new-item portfolio ${showAddItem === ADD_ITEM.PORTFOLIO ? 'active' : ''}`}
                                            onClick={() => { checkLogin() && nav.push('/new/portfolio') }}
                                        >
                                            <i className="fas fa-chart-pie"></i>
                                            <span>Portfolio</span>
                                        </button>
                                    </div>
                                </div>
                                <>
                                    {showAddItem === ADD_ITEM.QUESTION && <NewQuestion submitQuestionFn={(data) => submiQuestionTopic(data, FeedItemType.QUESTION)} />}
                                    {showAddItem === ADD_ITEM.TOPIC && <NewTopic submitTopicFn={(data) => submiQuestionTopic(data, FeedItemType.TOPIC)} />}
                                </>
                            </div>
                        </div>
                        <div className="col-2">   <div style={{ float: 'right' }}>
                            <HamburgerMenu /> </div>  </div>
                    </div>
                </div>
                <div className="row justify-content-center justify-content-lg-start">
                    <div className="col-lg-3 col-12 sj-feed-item px-2">
                        <div className="left-panel">
                            <div className="d-flex">
                                <img src={process.env.PUBLIC_URL + `/images/trending-topic.png`} height="20" />
                                <h5 style={{ color: 'purple', paddingLeft: '3px' }}>Trending News</h5>
                            </div>
                            {/* {trendItems.topics?.map((topic: BaseItem) => (
                                <div className="trending-item-wrapper">
                                    <TrendingItem key={topic.id} item={topic} state={state} setState={setState} />
                                </div>
                            ))} */}
                            {dailyTrendItemsTopic.Topic?.map((topic: BaseItem) => (
                                <div className="trending-item-wrapper">
                                    <DailyTrendingItem key={topic.id} item={topic} state={state} setState={setState} />
                                </div>
                            ))}
                            <div className=" my-5">
                                <div className="trending-item-wrapper">
                                    <Link to={'news/2'}>
                                        <USNewsMini state={state} setState={setState} position={2} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="py-3 px-2 fst-italic fw-light fs-6">
                            {/* <span className="pe-2">
                            <Link to={'/about'}>
                                <a className="link-secondary">About Us</a>
                            </Link>
                        </span>
                        <span className="text-muted">&bull;</span>
                        <span className="px-2">
                            <Link to={'/contact'}>
                                <a className="link-secondary">Contact Us</a>
                            </Link>
                        </span>
                        <span className="text-muted">&bull;</span>
                        <span className="ps-2">
                            <Link to={'/privacy-policy'}>
                                <a className="link-secondary">Privacy Policy</a>
                            </Link>
                        </span> */}


                            <Nav className="mx-auto justify-content-center">
                                {<Nav.Link href="https://apps.apple.com/us/app/stock-jabber/id1587176123" target="_blank">
                                    <img className="app-store-icon"
                                        src={`${process.env.PUBLIC_URL}/images/ios.png`}  //TODO fix the public_url prefix
                                        alt="Apple App Store" />
                                </Nav.Link>}
                                <br />
                                {!apple && <Nav.Link href="https://play.google.com/store/apps/details?id=com.datacent.stockjabber" target="_blank">
                                    <img className="app-store-icon"
                                        src={`${process.env.PUBLIC_URL}/images/android.png`}  // TODO fix the public_url prefix
                                        alt="Google Play Store" />
                                </Nav.Link>}
                            </Nav>

                        </div>
                    </div>
                    <div className="px-lg-5 col-12 col-lg-6 sj-feed-container">
                        {/* Feed Items  */}
                        {deviceType=="Desktop" && feedItems.map((item, index) => (
                            <FeedItem state={state} setState={setState} id={"web_"+item.id} key={item.id + '_' + index} item={item} updateItem={updateItem} />
                        ))}
                        <div className="w-100 d-flex justify-content-center my-2">
                            {/* <Button variant="outline-secondary"
                             onClick={getNextFeedItems}> View More </Button> */}
                        </div>
                    </div>
                    <div className="col-lg-3 sj-feed-item px-2">
                        <h5 style={{ color: "purple" }}>Trending Timelines</h5>
                        {
                            trends.filter(element => {
                                return element;
                            }).map((trend) => <>
                                <Link to={{ pathname: `item/hashtag/Trending/${trend}`, state: { trend } }} target="_blank" style={{ color: 'blue' }} className="title-link">
                                    <h6 className="fw-bold mt-1">
                                        #{trend}
                                    </h6>
                                </Link>
                            </>
                            )
                        }
                        <br />
                        <h5 style={{ color: "purple" }}>Trending Stocks</h5>
                        {dailyTrendItemsQuestion.Question?.map((question: BaseItem) => (
                            <div className="trending-item-wrapper">
                                <StocksUpDown key={question.id} item={question} state={state} setState={setState} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="d-block d-lg-none">
                <>
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10" style={{ paddingLeft: '10%' }}>
                            <input type="search" placeholder="Search" name="search" id="searchRevanth" style={{ width: '80%' }} />
                            <button type="submit" onClick={() => searchResults()}><i className="fa fa-search"></i></button>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="sj-feed-container mx-auto">
                                <div className="new-item-options-wrapper border border-1">
                                    <p className="text-muted ps-2 mb-0 add-item-help-text">Create Topic, Ask a Question, Get opinion on Portfolio</p>
                                    <div className="row g-0 text-center new-item-options">
                                        <div className="col-4">
                                            <button className={`btn new-item topic ${showAddItem === ADD_ITEM.TOPIC ? 'active' : ''}`}
                                                onClick={() => checkLogin() && showAddItem !== ADD_ITEM.TOPIC ? setShowAddItem(ADD_ITEM.TOPIC) : setShowAddItem(ADD_ITEM.NONE)}
                                            >
                                                <i className="fas fa-bullhorn"></i>
                                                <span>Topic</span>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <button className={`btn new-item question ${showAddItem === ADD_ITEM.QUESTION ? 'active' : ''}`}
                                                onClick={() => checkLogin() && showAddItem !== ADD_ITEM.QUESTION ? setShowAddItem(ADD_ITEM.QUESTION) : setShowAddItem(ADD_ITEM.NONE)}
                                            >
                                                <i className="fas fa-question-circle"></i>
                                                <span>Question</span>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <button className={`btn new-item portfolio ${showAddItem === ADD_ITEM.PORTFOLIO ? 'active' : ''}`}
                                                onClick={() => { checkLogin() && nav.push('/new/portfolio') }}
                                            >
                                                <i className="fas fa-chart-pie"></i>
                                                <span>Portfolio</span>
                                            </button>
                                        </div>
                                    </div>
                                    <>
                                        {showAddItem === ADD_ITEM.QUESTION && <NewQuestion submitQuestionFn={(data) => submiQuestionTopic(data, FeedItemType.QUESTION)} />}
                                        {showAddItem === ADD_ITEM.TOPIC && <NewTopic submitTopicFn={(data) => submiQuestionTopic(data, FeedItemType.TOPIC)} />}
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <img src={process.env.PUBLIC_URL + `/images/trending-topic.png`} height="20" />
                        <h5 style={{ color: 'purple', paddingLeft: '3px' }}>Trending News</h5>
                    </div>

                    <div style={{ zoom: '85%'}}>
                    <Carousel>
                        {dailyTrendItemsTopic.Topic?.map((topic: BaseItem) => (
                            <div className="trending-item-wrapper">
                                <DailyTrendingItem key={topic.id} item={topic} state={state} setState={setState} />
                            </div>
                        ))}
                    </Carousel>
                    </div>
                    
                    <h5 style={{ color: "purple" }}>Trending Timelines</h5>
                    {
                        trends.filter(element => {
                            return element;
                        }).map((trend) => <>
                            <Link to={{ pathname: `item/hashtag/Trending/${trend}`, state: { trend } }} target="_blank" style={{ color: 'blue' }} className="title-link">
                                <h6 className="fw-bold mt-1">
                                    #{trend}
                                </h6>
                            </Link>
                        </>
                        )
                    }

                    {/* <h5 style={{ color: "purple" }}>Trending Stocks</h5>
                    <Carousel>
                        {dailyTrendItemsQuestion.Question?.map((question: BaseItem) => (
                            <div className="trending-item-wrapper">
                                <StocksUpDown key={question.id} item={question} state={state} setState={setState} />
                            </div>
                        ))}
                    </Carousel> */}
                    <div className="row justify-content-center justify-content-lg-start">
                        <div className="col-lg-3 col-12 sj-feed-item px-2">
                            <div className="left-panel">
                                {/* <div className="d-flex">
                                    <img src={process.env.PUBLIC_URL + `/images/trending-topic.png`} height="20" />
                                    <h5 style={{ color: 'purple', paddingLeft: '3px' }}>Trending News</h5>
                                </div> */}
                                {/* {trendItems.topics?.map((topic: BaseItem) => (
                                <div className="trending-item-wrapper">
                                    <TrendingItem key={topic.id} item={topic} state={state} setState={setState} />
                                </div>
                            ))} */}
                                {/* {dailyTrendItemsTopic.Topic?.map((topic: BaseItem) => (
                                    <div className="trending-item-wrapper">
                                        <DailyTrendingItem key={topic.id} item={topic} state={state} setState={setState} />
                                    </div>
                                ))} */}
                                {/* <div className=" my-5">
                                    <div className="trending-item-wrapper">
                                        <Link to={'news/2'}>
                                            <USNewsMini state={state} setState={setState} position={2} />
                                        </Link>
                                    </div>
                                </div> */}
                            </div>
                            <div className="py-3 px-2 fst-italic fw-light fs-6">
                                {/* <span className="pe-2">
                            <Link to={'/about'}>
                                <a className="link-secondary">About Us</a>
                            </Link>
                        </span>
                        <span className="text-muted">&bull;</span>
                        <span className="px-2">
                            <Link to={'/contact'}>
                                <a className="link-secondary">Contact Us</a>
                            </Link>
                        </span>
                        <span className="text-muted">&bull;</span>
                        <span className="ps-2">
                            <Link to={'/privacy-policy'}>
                                <a className="link-secondary">Privacy Policy</a>
                            </Link>
                        </span> */}
                            </div>
                        </div>
                        <div className="px-lg-5 col-12 col-lg-6 sj-feed-container">
                            {/* Feed Items  */}
                            {deviceType=="Mobile" && feedItems.map((item, index) => (
                                <FeedItem state={state} setState={setState} id={"mob_"+item.id} key={item.id + '_' + index} item={item} updateItem={updateItem} />
                            ))}
                            <div className="w-100 d-flex justify-content-center my-2">
                                <Button variant="outline-secondary" onClick={getNextFeedItems}> View More </Button>
                                <br/ >
                            </div>
                        </div>
                        <div className="col-lg-3 sj-feed-item px-2">
                            <h5 style={{ color: "purple" }}>Trending Timelines</h5>
                            {
                                trends.filter(element => {
                                    return element;
                                }).map((trend) => <>
                                    <Link to={{ pathname: `item/hashtag/Trending/${trend}`, state: { trend } }} target="_blank" style={{ color: 'blue' }} className="title-link">
                                        <h6 className="fw-bold mt-1">
                                            #{trend}
                                        </h6>
                                    </Link>
                                </>
                                )
                            }
                            <br />
                            {/* <h5 style={{ color: "purple" }}>Trending Stocks</h5>
                            {dailyTrendItemsQuestion.Question?.map((question: BaseItem) => (
                                <div className="trending-item-wrapper">
                                    <StocksUpDown key={question.id} item={question} state={state} setState={setState} />
                                </div>
                            ))} */}
                        </div>
                    </div>

                </>
            </div >
        </>
    )
}

export default MainFeed
