// src/App.js
import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
function Profile({ state, setState }) {
    const { session } = state;
    const saveDB = (event) => alert("Yoo please save");
    return (
        <div className="tab-wrapper">
            <div className='container-fluid' >
                <div className="row">
                    <div className="col-md-12">
                        <Tabs defaultActiveKey="profile">
                            <Tab eventKey="PersonalInformation" title="Personal Information">
                                <div className="tab-item-wrapper">
                                <br />
                                    <h5 style={{textAlign:'center'}}><i>Personal Information</i></h5>
                                    <br />
                                    <table className="table table-bordered table-striped mx-auto w-auto">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Name</th>
                                                <th contenteditable="true">@Service Name</th>
                                            </tr>
                                            <tr>
                                                <th scope="row">Email</th>
                                                <th contenteditable="true">@Service</th>
                                            </tr><tr>
                                                <th scope="row">Phone No</th>
                                                <th contenteditable="true">@Service</th>
                                            </tr><tr>
                                                <th scope="row">Location</th>
                                                <th contenteditable="true">@Service</th>
                                            </tr><tr>
                                                <th scope="row">Trading Type &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                                <th contenteditable="true">@Service</th>
                                            </tr>
                                            <tr>
                                                <th scope="row">Trader Type</th>
                                                <th contenteditable="true">@Service</th>
                                            </tr>
                                            <tr>
                                                <th scope="row">Experience</th>
                                                <th contenteditable="true">@Service</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p className='text-center'>You can click, modify personal information and Save  <br /> <br />
                                        <button type="button" id="saveInfo" onClick={saveDB}>Save</button>
                                    </p>
                                </div>
                            </Tab>
                            {/* <Tab eventKey="YourPosts" title="YourPosts">
                                <div className="tab-item-wrapper">
                                    <h5 style={{textAlign:'center'}}>Your Posts</h5>
                                </div>
                            </Tab> */}
                            <Tab eventKey="Notifications" title="Notifications">
                                <br />
                                <div className="tab-item-wrapper">
                                    <br />
                                    <h5 style={{textAlign:'center'}}><i>Enable Notifications of your choice</i></h5>
                                    <br/>
                                    <table className="table table-bordered table-striped mx-auto w-auto">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Push &nbsp;&nbsp;&nbsp;&nbsp;</th>
                                                <th scope="col">Email &nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Topic</th>
                                                <td>   <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="topicPushSwitch" />
                                                </div></td>
                                                <td>   <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="topicEmailSwitch" />
                                                </div></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Question</th>
                                                <td>                                  <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="questionPushSwitch" />
                                                </div>
                                                </td>
                                                <td>                                  <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="questionEmailSwitch" />
                                                </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Portfolio &nbsp;&nbsp;&nbsp;&nbsp;</th>
                                                <td> <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="portfolioPushSwitch" />
                                                </div></td>
                                                <td> <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="portfolioEmailSwitch" />
                                                </div></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Articles</th>
                                                <td> <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="articlesPushSwitch" />
                                                </div></td>
                                                <td> <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="articlesEmailSwitch" />
                                                </div></td>
                                            </tr>
                                        </tbody>
                                    </table>                            <br />
                                </div>
                            </Tab>
                            <Tab eventKey="Feedback" title="Feedback">
                                <div className="tab-item-wrapper">
                                    <br/>
                                    <div className='row'>
                                        <div className="col-md-9">
                                        <h5 style={{textAlign:'center'}}><i> Feedback Form</i></h5>
                                        </div>
                                        <div className="col-md-2">
                                            <h6><u><i>Suggest StockJabber</i>&nbsp;&nbsp;<i className="bi bi-share-fill"></i></u></h6>
                                        </div>
                                        <div className="col-md-1">
                                            <h6><u><i>RateUs</i>&nbsp;&nbsp; <i className="bi bi-stars"></i></u></h6>
                                        </div>
                                    </div>
                                    <br/>
                                    <p>Please provide your feedback. Your suggestions are valuable to us and we are working relentlessly to make
                                        better Pinstox for your needs. </p>
                                        <br/>
                                        <table className="table table-bordered table-striped mx-auto w-auto">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Name</th>
                                                <th><input type="textbox"></input></th>
                                            </tr>
                                            <tr>
                                                <th scope="row">Feature</th>
                                                <th><input type="textbox"></input></th>
                                            </tr>
                                            <tr>
                                                <th scope="row">Feedback Type</th>
                                                <th>
                                                    <select>
                                                        <option defaultChecked>Select</option>
                                                        <option>Improvement</option>
                                                        <option>Complaint</option>
                                                        <option>Other</option>
                                                    </select>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th scope="row">Details</th>
                                                <th><input type="textarea" style={{minWidth:"500px"}}></input></th>
                                            </tr>
                                        </tbody>
                                    </table>
                                        
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Profile;