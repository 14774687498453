import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { BaseProp } from '../../../model/BaseResource.model'
import { USNews } from '../../../model/USNews.model';
import { getHomePageNews } from '../../../services/USNews.service';
import Linkify from 'react-linkify';

export interface USNewsProps extends BaseProp {
    position?: number;
    fullWidth?: boolean;
}

function USNewsMini({ state, setState, position, fullWidth }: USNewsProps) {
    let { position: _position } = useParams<{position: any}>();
    _position = +_position;
    position = position || _position;
    
    const [news, setNews] = useState<USNews>(undefined);
    const [publishedDate, setPublishedDate] = useState(undefined);

    const formatDate = (date: Date) => (
        `${date.getFullYear()}/${date.getMonth() + 1 }/${date.getDay()}`
    )

     useEffect(() => {
         const getNews = async () => {
            getHomePageNews(position || 1)
            .then(_news => { 
                // setPublishedDate(new Date(_news?.publicationDate?.replace(/ /g,"T"))); 
                 setNews(_news); 
             })
         };

       getNews();
    }, []);

    return news ? fullWidth ? 
    ( // full width 
        <> 
            <div className="row">
                <div className="col-10 mx-auto">
                    <h1>{ news.title }</h1>
                    <br />
                    {news.keywords}
                    <br />
                    <img src={news.image_url} alt={news.description} width="75%"/>
                    <div className="text-muted fst-italic fs-6" title={publishedDate.toString()}>{ formatDate(publishedDate) }</div>
                    <br />
                    {news.ticker}
                    <br />
                    <Linkify>
                        {news.description}
                    </Linkify>
                    <br />
                    <br />
                    {news.url}
                </div>
            </div>
        </>
    ) : // mini 
    (
        <>
            <img src={news.image_url} alt={news.description} width="100%"/>
            <div className="text-muted fst-italic fs-6" title={publishedDate.toString()}>{ formatDate(publishedDate) }</div>
            <div>{ news.title }</div>
        </>
    )
    : <></>
}

export default USNewsMini