import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { getSearchResults } from '../../services/HomePage.service';
import { kebabCase } from 'lodash';
import Avatar from 'react-avatar';

function Search({ state, setState }: any) {
    const [searchElement, setSearchElement] = useState(false);
    const [items, setItems] = React.useState({ result: [] });
    const [search, setSearch] = React.useState('');

    useEffect(() => {
        (async () => {
            try {

                setSearch(window.location.search.split('=')[1]);
                console.log(search);
                const searchItems = await getSearchResults(search.toString());
                setSearchElement(true);
                setItems(searchItems);
            } catch (e) {
                console.error(e);
            }
        })();
    }, [items, search])

    return (
        <div className="row justify-content-center">
            <div className="col-md-10 col-12 mx-atuo">
                <div className="sj-standalone-item-container">
                    {searchElement && <>
                        {items?.result?.length > 0 && <>
                            <p className="h3 mt-4">Search Results</p>
                            <br /> 
                            <div>
                                <h5> Below were the links which contains your search criteria: <p style={{backgroundColor:'yellow', display:'inline-block'}}>{search}</p> </h5>
                                {items.result?.map((item) => (
                                    <div>
                                        {/* <a href={`item/${item.type}/${item.id}/${kebabCase(item.title)}`.toLocaleLowerCase()}>{item.title}</a>
                                      <br/> <br/> */}


                                        <div className="d-flex pb-1">
                                            <div className="mx-1">
                                                <Avatar size={"25"} round={true} textSizeRatio={1} name={item.postedby} maxInitials={2}></Avatar>
                                            </div>
                                            <div className="mx-1">
                                                <div className="d-flex flex-column">
                                                    <a href={`item/${item.type}/${item.id}/${kebabCase(item.title)}`.toLocaleLowerCase()}>
                                                        <h6 className="fw-bold mt-1">
                                                            {item.title?.replace(/\n/g, ' ').substring(0, 100)}
                                                        </h6>
                                                    </a>
                                                </div>
                                                <div>
                                                    <p className="mb-1 trending-item-summary">
                                                        {item.summary?.replace(/\n/g, ' ').substring(0, 150)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </>
                        }
                    </>

                    }
                    {!searchElement && <h3>Loading...</h3>}
                </div>
            </div>
        </div>
    )
}

export default Search
