import { BaseResource, PortfolioFlag, QuestionReply, TopicFlags } from "../model/BaseResource.model";
import { BaseItem } from "./HomePage.model";
import { post, SJHttpResponse } from "./RepoService";


/**
 * REPLY COMMENTS AND LIKE/DISLIKES (AGREE / DISAGREE)
 */

////// QUESTION 

export function postQuestionReply(questionId: number, userId: number, payload: string): Promise<BaseItem> {
    const content = { questionId, questionReplydby: userId, questionReplySummary: payload }
    return post<any>(`/PostQuestionreply`, content).then(res => res.result[0]);
}

export function postQuestionReplyComment(questionReplyId: number, userId: number, payload: string): Promise<BaseItem> {
    const content = { questionReplyId, questionReplyCommentdby: userId, questionReplyCommentSummary: payload}
    return post<any>(`/PostQuestionreplycomment`, content).then(res => res.result[0])
}

export function postQuestionReplyHelpful(questionreplyId: number, userId: number): Promise<BaseItem> {
    return post<any>(`/Postreplyhelpful`, { questionreplyId, questionreplyHelpfulby: userId })
        .then(res => res.result[0]);
}

// noop.. not implemented on the backend
// export function postQuestionReplyCommentHelpful(questionreplycommentId: number, userId: number): Promise<SJHttpResponse> {
//     return post<any>(`/Postreplycommenthelpful`, { questionreplycommentId, questionreplycommentHelpfulby: userId })
// }

////// TOPIC

export function postTopicReply(topicId: number, userId: number, payload: string): Promise<BaseItem> {
    return post<any>(`/PostTopicreply`, { topicId, topicReplydby: userId, topicReplySummary: payload })
        .then(res => res.result[0]);
}

export function postTopicReplyComment(topicReplyId: number, userId: number, payload: string): Promise<BaseItem> {
    return post<any>(`/PostTopicreplycomment`, { topicReplyId, topicReplyCommentdby: userId, topicReplyCommentSummary: payload })
        .then(res => res.result[0]);
}

export function postTopicAgreeDisagree(topicId: number, userId: number, flag: TopicFlags): Promise<BaseItem> {
    return post<any>(`/PostTopicagreedisagree`, { topicId, topicReplydby: userId, flag })
        .then(res => res.result[0]);
}

export function postTopicReplyLike(topicReplyId, userId: number): Promise<BaseItem> {
    return post<any>(`/PostTopicreplylike`, { topicReplyId, topicReplyLikeby: userId })
        .then(res => res.result[0]);
}

/////// PORTFOLIO

export function postPortfolioReply(portfolioId: number, userId: number, payload: string): Promise<BaseItem> {
    return post<any>(`/portfolio/PostReply`, { portfolioId, portfolioreplyby: userId, replysummary: payload})
        .then(res => res.result[0]);
}

export function postPortfolioReplyComment(portfolioReplyId: number, userId: number, payload: string): Promise<BaseItem> {
    return post<any>(`/portfolio/PostReplyComment`, { portfolioReplyId, portfolioReplyCommentby: userId, portfolioReplyComment: payload})
        .then(res => res.result[0]);
}

export function postPortfolioRecommendDontRecommend(portfolioId: number, userId: number, flag: PortfolioFlag): Promise<BaseItem> {
    return post<any>(`/portfolio/PostRecommend_dontrecommend`, { portfolioId, portfolioReplydby: userId, flag})
        .then(res => res.result[0])
}

export function postPorfolioHold(portfolioId: number, userId: number): Promise<BaseItem> {
    return post<any>(`/portfolio/PostHold`, { portfolioId, portfolioholdby: userId })
        .then(res => res.result[0])
}

export function postPortfolioReplyLike(portfolioReplyId: number, userId: number): Promise<BaseItem> {
    return post<any>(`/portfolio/PostReplyLike`, { portfolioReplyId, portfolioReplyLikeby: userId })
        .then(res => res.result[0]);
}