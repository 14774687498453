import React, { useState } from 'react'
import { BaseProp } from '../../model/BaseResource.model';
import Avatar from 'react-avatar';

export default function NewComment({ 
    state, 
    setState,
    post
}: BaseProp ) {
    const { session } = state;
    const [content, setContent] = useState('');

    const id = `sj-comment-compose-${Date.now()}`

    // setTimeout(() => {
    //     try {
    //         let pageY = window.scrollY;
    //         const elm = document.querySelector(`#${id}`);
    //         elm['scrollIntoViewIfNeeded']();
    //         if (pageY !== window.scrollY)
    //             window.scrollBy(0, -400);
    //     } catch {}
    // }, 500);

    return (
        <div className="composeReply">
            <div className="d-flex py-1">
                <div className="mx-1 mt-2">
                    <Avatar size={"35"} round={true} textSizeRatio={1} name={session?.userFullname} maxInitials={2}></Avatar>
                </div>
                <div className="mx-1 w-100">
                    <div className="d-flex mb-2">
                        <div className="fw-bold">
                            { session?.user?.firstName } { session?.user?.lastName }
                        </div>
                    </div>
                    <div className="d-flex composereplyContainer">
                        <div className="w-100">
                            <textarea 
                                name="composereply" 
                                id={id} 
                                className="composeReply form-control"
                                onChange={ (event) => setContent(event.target.value) }
                            ></textarea>
                        </div>
                        <div>
                            <button className="btn btn-outline-secondary border-0"
                                onClick={() => content?.trim() && post(content) }
                            ><i className="far fa-paper-plane"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    )
}
