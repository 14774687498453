import { BaseItem } from "./HomePage.model";
import { getBaseHref } from "../url-base-href-ts";
import { SSL_OP_NO_TLSv1_1 } from "constants";

// const BASE_HREF = `${URL_CONFIG.prod.web}/sj`;

const BASE_HREF = getBaseHref();
    
console.debug({BASE_HREF});

export interface Pagination<T> {
    result: T[];
    status: number;
}

export interface SJHttpResponse {
    result: any;
    status: number;
    message?: string;
}

export interface StockTicker {
    tag: string;
    ticker: string;
}

function remapErrors(res: SJHttpResponse){
    if (res.status >= 400)
       throw Error(`Error: ${res.status}, Message: ${res.message}`); 
    return res;
}

/**
 * Only use this to GET JSON response 
 * 
 * @param path the path name 
 * @returns 
 */
export async function get<T>(path: string): Promise<Pagination<T> | SJHttpResponse> {
    return await (await fetch (BASE_HREF + path)).json().then(remapErrors);
}

/**
 * Use for POST JSON response 
 * 
 * @param path the path name
 * @returns 
 */
export async function post<T>(path: string, data: any): Promise<SJHttpResponse | T> {
    return await (await fetch(BASE_HREF + path, { 
        method: 'POST', 
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    })).json().then(remapErrors);
}


/**
 * Gets Topic Info 
 * NOT USED
 *
 */
export function getTopicInfo(id: number|string, page = 1, items = 100) {
    return get<BaseItem>(`/GetTopicInfo/${id}/${page}/${items}`)
        .then(response => response.result[0]);
}


/**
 * Get QuestionInfo
 * NOT USED
 * 
 */
export function getQuestionInfo(id: number|string, page = 1, items = 100) {
    return get<BaseItem>(`/GetQuestionInfo/${id}/${page}/${items}`)
        .then(response => response.result[0]);
}

/**
 * Get Portfolio 
 * NOT USED
 * 
 */
export function getPortfolioInfo(id: number, page = 1, items = 100) {
    return get<BaseItem>(`/portfolio/GetPortfolioInfo/${id}/${page}/${items}`)
        .then(response => response.result[0])
}


export function getHomePageItemDetail(id: number, page = 1, items = 100) {
    return get<BaseItem>(`/GetHomeItemDetails/${id}/${page}/${items}`)
        .then(response => response.result[0]);
}

export function getHomePageTrendingItemDetail(id: number, page = 1, items = 10) {
    return get<BaseItem>(`/getDailyTrendingItemById/${id}/${page}/${items}`)
        .then(response => response.result);
}

/////////////// POST 

// POST TOPIC 

export function postTopic(title: string, summary: string, userId: number, gifUrl: string, imageUrl:string, socialUrl: string) {
    return post<any>(`/PostTopic`, { topicTitle: title, topicSummary: summary, topicPostedby: userId ,tickers:'',imageId:'',gif_url: gifUrl,image_url: imageUrl, social_url: socialUrl})
        .then(response => response.result[0]);
}

// POST QUESTION

export function postQuestion(title: string, summary: string, userId: number, gifUrl: string, imageUrl:string, socialUrl: string) {
    return post<any>(`/PostQuestion`, { questionTitle: title, questionSummary: summary, questionPostedby: userId, tickers:'',imageId:'',gif_url: gifUrl, image_url: imageUrl, social_url: socialUrl})
        .then(response => response.result[0]);
}

// Post Image

export function imageUpload(title:string, description:string, source:string, tickers:string, create_by:string, file:FormData){
    return post<any>(`/image_catalog/addImage`, {title: title, description:description, source:source, tickers:tickers,create_by:create_by,file:file})
    .then(response => response.result[0]);
}

// Create Portfolio 

export function postCreatePortfolio(payload: any, userId: number): Promise<BaseItem> {
    return post<any>(`/portfolio/create_portfolio`, { ...payload, userId })
        .then(response => response.result[0]);
}

// preview portfolio
export function postPreviewPortfolio(payload: any, userId: number): Promise<BaseItem> {
    return post<any>(`/portfolio/preview_portfolio`, { ...payload, userId })
        .then(response => response.result[0]);
}

/// GET ALL TICKERS 

export function getAllTickers(): Promise<StockTicker[]> {
    return get<any>(`/getAllTickersInfo`)
        .then(response =>  response.result);
}