import React, { useReducer, useRef, useState } from "react";
import { kebabCase } from 'lodash';
import Avatar from 'react-avatar';
import { PieChart } from 'react-minimal-pie-chart';
import { Button } from "react-bootstrap";
import Linkify from 'react-linkify';
import { Link, useHistory } from "react-router-dom";
import { InstagramEmbed, FacebookEmbed, YouTubeEmbed, TwitterEmbed, TikTokEmbed, LinkedInEmbed } from 'react-social-media-embed';
import { BaseProp, BaseReply, FeedItemType, ItemKeyMap, PortfolioFlag, TopicFlags } from '../../model/BaseResource.model'
import { BaseItem } from "../../services/HomePage.model";
import Comments from "../Comments/Comments";
import "./FeedItem.scss";
import NewComment from "../Comments/NewComment";
import { postPorfolioHold, postPortfolioRecommendDontRecommend, postPortfolioReply, postPortfolioReplyComment, postPortfolioReplyLike, postQuestionReply as postQuestionReplyService, postQuestionReplyComment, postQuestionReplyHelpful, postTopicAgreeDisagree, postTopicReply, postTopicReplyComment, postTopicReplyLike } from "../../services/Reply.service";
import { redirectIfNotLoggedIn } from "../../services/Session.service";
import { getHashtagDetails } from "../../services/HomePage.service";
import { getBaseHref } from "../../url-base-href-ts";
const BASE_HREF = getBaseHref();

const roundNumber = (num: number, decimal = 2) => Math.round(num * (10 ^ decimal)) / (10 ^ decimal);
const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

interface FeedItemProps extends BaseProp {
    item: BaseItem,
    standaloneView?: boolean
}

function FeedItem({
    item: item,
    standaloneView,
    state,
    setState,
    updateItem
}: FeedItemProps) {
    // console.log(`item from state, `, item);

    const { session } = state;
    const nav = useHistory();
    const [, forceUpdate] = useReducer(_ => _ + 1, 0);
    
    const itemLink = `item/${item.type}/${item.id}/${kebabCase(item.title)}`.toLocaleLowerCase();
    
    standaloneView = !!standaloneView
    const shareLink = (event) => {
        if (navigator.share) {
            let summary = item.type === FeedItemType.PORTFOLIO ? item.reason : item.summary;
            summary = (summary as string).length > 200 ? `${summary.substring(0, 200)}...` : summary;
            navigator.share({
                title: `StockJabber - ${item.title}`,
                text: `Stockjabber - ${item.title}\n${summary}\n\n`,
                url: `https://stockjabber.com/${itemLink}`
            })
        } else console.log(`share noop`)
    }

    const [viewMore, setViewMore] = useState(standaloneView);
    const [showNewComment, setShowNewComment] = useState(false);
    // const [hashTag, sethashTag] = useState(false);

    // if(item.type == 'Topic' || item.type == 'Question'){
    //     sethashTag(false);
    // }
    // else{
    //     sethashTag(true);
    // }
    const _setViewMore = (event) => {
        setViewMore(!viewMore)
    }

    const triggerShowComment = (override = !showNewComment) => {
        if (!session?.user) {
            if (window.confirm('Please login to comment')) {
                sessionStorage.setItem(`redirect_after_login`, nav.location.pathname);
                nav.push(`/login`);
            }
        }
        else setShowNewComment(override);
    }

    const downloadApp = () => {
        alert(`Download the StockJabber App`);
    }


    /// PORTFOLIO PIE CHART COLOR 
    const randomColor = (index) => {
        // Math.floor(Math.random()*16777215).toString(16)
        const colors = [
            '#ff0000',
            '#000080',
            '#556B2F',
            '#6495ED',
            '#800080',
            '#8B4513',
            '#CD5C5C',
            '#D2691E',
            '#DB7093',
            '#FF8C00',
        ];
        return colors[index % colors.length];
    };

    const addReply = async (item: BaseItem, summary: string) => {
        if (!summary?.trim())
            return;

        const addQuestionReply = async () => {
            const afterAddingReply = await postQuestionReplyService(item.id, session.user.id, summary);
            item.questionreplys = [afterAddingReply.questionreplys[0], ...item.questionreplys];
            item.replycount = afterAddingReply.replycount;
            updateItem({ ...item });
        }

        const addTopicReply = async () => {
            const afterAddingReply = await postTopicReply(item.id, session.user.id, summary);
            item.topicreplys = [afterAddingReply.topicreplys[0], ...item.topicreplys];
            item.replycount = afterAddingReply.replycount;
            updateItem({ ...item });
        }

        const addPortfolioReply = async () => {
            const afterAddingReply = await postPortfolioReply(item.id, session.user.id, summary);
            item.protfolioreply = [afterAddingReply.protfolioreply[0], ...item.protfolioreply];
            item.replycount = afterAddingReply.replycount;
            updateItem({ ...item });
        }


        try {
            switch (item.type) {
                case FeedItemType.QUESTION: await addQuestionReply();
                    break;

                case FeedItemType.TOPIC: await addTopicReply();
                    break;

                case FeedItemType.PORTFOLIO: await addPortfolioReply();
                    break;
            }
        } catch (e) {
            console.error(e);
            alert(`There was an error adding the reply.. `);
        }
    }


    const addReplyToReply = async (comment: BaseReply, summary: string) => {
        if (!summary?.trim())
            return;

        // question reply to reply
        const addQuestionReply = async () => {
            const afterAddingReply = await postQuestionReplyComment(comment.id, session.user.id, summary);
            afterAddingReply.questionreplys = item.questionreplys.map((itemQuestionReply) => {
                if (itemQuestionReply.id === afterAddingReply.questionreplys[0].id) {
                    itemQuestionReply.replyCommentcount = afterAddingReply.questionreplys[0].replyCommentcount;
                    itemQuestionReply.reply = [afterAddingReply.questionreplys[0].reply[0], ...itemQuestionReply.reply]
                }
                return itemQuestionReply;
            });

            updateItem(afterAddingReply);
        }

        // topic reply to reply
        const addTopicReply = async () => {
            const afterAddingReply = await postTopicReplyComment(comment.id, session.user.id, summary);
            afterAddingReply.topicreplys = item.topicreplys.map((itemTopicReply) => {
                if (itemTopicReply.id === afterAddingReply.topicreplys[0].id) {
                    itemTopicReply.replyCommentcount = afterAddingReply.topicreplys[0].replyCommentcount;
                    itemTopicReply.reply = [afterAddingReply.topicreplys[0].reply[0], ...itemTopicReply.reply]
                }
                return itemTopicReply;
            });

            updateItem(afterAddingReply);
        }

        // portfolio reply to reply 
        const addPortfolioReply = async () => {
            const afterAddingReply = await postPortfolioReplyComment(comment.id, session.user.id, summary);
            afterAddingReply.protfolioreply = item.protfolioreply.map((itemPortolioReply) => {
                if (itemPortolioReply.id === afterAddingReply.protfolioreply[0].id) {
                    itemPortolioReply.replyCommentcount = afterAddingReply.protfolioreply[0].replyCommentcount;
                    itemPortolioReply.reply = [afterAddingReply.protfolioreply[0].reply[0], ...itemPortolioReply.reply]
                }
                return itemPortolioReply;
            });

            updateItem(afterAddingReply);
        }

        try {
            switch (item.type) {
                case FeedItemType.QUESTION: await addQuestionReply();
                    break;

                case FeedItemType.TOPIC: await addTopicReply();
                    break;

                case FeedItemType.PORTFOLIO: await addPortfolioReply();
                    break;
            }
        } catch (e) {
            console.error(e);
            alert(`There was an error adding the reply.. `);
        }
    }

    const topicAgreeDisagree = (topicId, flag) => {
        if (!checkLogin()) return;

        const addTopicAgreeDisagree = async () => {
            const after = await postTopicAgreeDisagree(topicId, session.user.id, flag);
            item.topicagreecount = after.topicagreecount;
            item.topicdisagreecount = after.topicdisagreecount;
            item = { ...item };
            updateItem(item);
        }

        try {
            addTopicAgreeDisagree();
        } catch (e) {
            console.error(e);
        }
    }

    const portfolioReccDontRecc = async (flag) => {
        if (!checkLogin()) return;

        try {
            const res = await postPortfolioRecommendDontRecommend(item.id, session.user.id, flag);
            item.portfoliRecommendCount = res.portfoliRecommendCount;
            item.portfoliDontRecommendCount = res.portfoliDontRecommendCount;
            item = { ...item };
            updateItem(item);
        } catch (e) {
            console.error(e);
        }
    }

    const portfolioHold = async () => {
        if (!checkLogin()) return;

        try {
            const res = await postPorfolioHold(item.id, session.user.id);
            item.portfolioHoldCount = res.portfolioHoldCount;
            item = { ...item };
            updateItem(item);

        } catch (e) {
            console.error(`there was a problem holding portfoio`, e);
        }
    }

    const commentLike = async ({ id } /* entire comment object */) => {
        if (!checkLogin()) return;

        const questionReplyLike = async () => {
            const res = await postQuestionReplyHelpful(id, session.user.id);
            const questionReply = item.questionreplys.find(qr => qr.id === res.questionreplys[0].id);
            if (questionReply) {
                questionReply.questionHelpful = res.questionreplys[0].questionHelpful;
                item = { ...item };
                updateItem(item);
            } else {
                console.error(`Question reply Id not found`);
            }
        }

        const topicReplyLike = async () => {
            const res = await postTopicReplyLike(id, session.user.id);
            const topicReply = item.topicreplys.find(tr => tr.id === res.topicreplys[0].id);
            if (topicReply) {
                topicReply.likecount = res.topicreplys[0].likecount;
                item = { ...item };
                updateItem(item);
            } else {
                console.error(`Topic reply Id not found`);
            }
        }

        const portfolioReplyLike = async () => {
            const res = await postPortfolioReplyLike(id, session.user.id);
            const protfolioreply = item.protfolioreply.find(tr => tr.id === res.protfolioreply[0].id);
            if (protfolioreply) {
                protfolioreply.likecount = res.protfolioreply[0].likecount;
                item = { ...item };
                updateItem(item);
            } else {
                console.error(`Portfolio reply Id not found`);
            }
        }

        try {
            switch (item.type) {
                case FeedItemType.QUESTION: await questionReplyLike();
                    break;

                case FeedItemType.TOPIC: await topicReplyLike();
                    break;

                case FeedItemType.PORTFOLIO: await portfolioReplyLike();
                    break;
            }
        } catch (e) {
            console.error(e);
        }
    }

    const commentReplyLike = async ({ id } /* entire comment object */) => {
        if (!checkLogin()) return;

        const questionReplyCommentLike = async () => {
            // noop.. not implemented yet
        }

        const topicReplyCommentLike = async () => {
            // noop.. not implemented yet
        }

        const portfolioReplyCommentLike = async () => {
            // noop.. not implemented yet
        }

        try {
            switch (item.type) {
                case FeedItemType.QUESTION: await questionReplyCommentLike();
                    break;

                case FeedItemType.TOPIC: await topicReplyCommentLike();
                    break;

                case FeedItemType.PORTFOLIO: await portfolioReplyCommentLike();
                    break;
            }
        } catch (e) {
            console.error(e);
        }
    }

    const checkLogin = () => {
        try {
            return redirectIfNotLoggedIn(session, nav);
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    return (
        <>
            <div className={`sj-feed-${item.type.toLowerCase()} sj-feed-item`}>
                <p className={`sj-feed-item-${item.type.toLowerCase()} feed-item-type fw-bold`} >{item.type}</p>
                <span className="d-flex justify-content-between">
                    <Link to={{ pathname: `/${itemLink}`, state: { item } }} className="title-link">
                        <h4 className={(standaloneView ? `sj-feed-item-${item.type.toLowerCase()} text-sm-start text-center` : '') + ''}>{item.title}</h4>
                    </Link>
                    <Button variant="link" className="p-0 border-0 text-black m-1 align-self-start" onClick={shareLink}>
                        <i className="fa fa-share"></i>
                    </Button>
                </span>
               {/* {!hashTag && <>  */}
                <div className="d-flex align-items-center mb-2">
                    {/* <img alt={item.postedby} className="sj-user-avatar" src={`${process.env.PUBLIC_URL}/images/profile-xs.png`}/> */}
                    <Avatar
                        size={"40"} round={true} textSizeRatio={1} name={item.postedby}
                        maxInitials={2}
                    ></Avatar>
                    <div className="d-inline-flex flex-column ms-2">
                        <span className="mx-1">
                            {item.postedby}
                        </span>
                        <span
                            className="mx-1 small"
                            title={(new Date(item.postedate).toString())}>
                            {item.timedifference == "0" ? 'Just now' : item.timedifference || 'now'}
                        </span>
                    </div>
                </div>
                <div className="feed-image placeholder-wave">
                    {item.imageURLS && item.imageURLS.length > 0 && item.imageURLS.map((imageSrc, index) => (
                        <img width="100%" key={"img-inline-" + item.id + index} onLoad={(e) => {
                            (e.target as any).classList.remove("placeholder");
                            (e.currentTarget.parentNode as any).classList.remove("placeholder-wave");
                        }} className="feed-inline-image placeholder" onError={(e) => {
                            (e.target as any).src = '';
                            (e.target as any).classList.remove("placeholder");
                            (e.currentTarget.parentNode as any).classList.remove("placeholder-wave");
                        }} src={imageSrc} />
                    ))}
                </div>
                {/* </>} */}
                {/* // Keywords are removed temporarily 
                    item.keywords && <p>
                        <span className="fw-bold">Keywords:</span> { (item.keywords || "").replaceAll(',', ', ')}
                    </p>
                */}
                {!!~[FeedItemType.TOPIC, FeedItemType.QUESTION].indexOf(item.type) &&
                    <>
                        {!standaloneView &&
                            <>
                                <input
                                    type="checkbox"
                                    checked={!!viewMore}
                                    onChange={_setViewMore}
                                    id={`item_${item.id}`} />
                                <p className="item-summary item-truncate pre-line">
                               <div style={{maxWidth:'400px', maxHeight:'400px'}}> {item.image_url && <img src={BASE_HREF + item.image_url.replace('\sj','')} className="img-fluid" width="100%" />} </div>
                                    <Linkify>
                                        {item.summary}
                                    </Linkify>
                                </p>
                                <label role="button" htmlFor={`item_${item.id}`} className="small">View More</label>
<br />
                                {item.gif_url && <img src={item.gif_url} className="img-fluid" width="100%" />}
                               
                                {item.social_url && (item.social_url.includes('facebook') ?
                                    <FacebookEmbed url={item.social_url} /> :
                                    (item.social_url.includes('instagram') ?
                                        <InstagramEmbed url={item.social_url} /> :
                                        (item.social_url.includes('youtube') ?
                                        <div style={{zoom:'70%'}}> <YouTubeEmbed url={item.social_url} /></div> :
                                            ((item.social_url.includes('twitter') || item.social_url.includes('x.com')) ?
                                                <TwitterEmbed url={item.social_url} /> :
                                                (item.social_url.includes('tiktok') ?
                                                    <TikTokEmbed url={item.social_url} /> :
                                                    (item.social_url.includes('linked') ?
                                                        <LinkedInEmbed url={item.social_url} /> : ''
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                                }  </>
                        }

                        {standaloneView &&
                            <>
                                <div className="row justify-content-center justify-content-lg-start">
                                    <div className="col-lg-3 col-12 sj-feed-item">
                                        <div className="left-panel">
                                            {/* {trendItems.topics?.map((topic: BaseItem) => (
                                <div className="trending-item-wrapper">
                                    <TrendingItem key={topic.id} item={topic} state={state} setState={setState} />
                                </div>
                            ))} */}

                                        </div>
                                    </div>
                                    <div className="sj-feed-container">
                                        {/* Feed Items  ==px-lg-5 col-12 col-lg-6 */}
                                        <div>
                                            {item.gif_url && <img src={item.gif_url} className="img-fluid" width="50%" height="50%" style={{ maxHeight: '50%', maxWidth: '50%' }} />}

                                            {item.image_url && <img src={BASE_HREF + item.image_url.replace('\sj','')} className="img-fluid" width="100%" />}
                                        </div>
                                        <p className="item-truncate pre-line">
                                            <Linkify>
                                                {renderHTML(item.summary)}
                                            </Linkify>
                                        </p>
                                        <div style={{ justifyContent: 'center', textAlign: 'center', maxHeight: '50%', maxWidth: '100%' }}>
                                            {item.social_url && (
                                                item.social_url.includes('facebook') ?
                                                    <FacebookEmbed url={item.social_url} /> :
                                                    (item.social_url.includes('instagram') ?
                                                        <InstagramEmbed url={item.social_url} width="80%" height="50%" /> :
                                                        (item.social_url.includes('youtube') ?
                                                        <div style={{ zoom: '80%' }}>  <YouTubeEmbed url={item.social_url} /> </div> :
                                                            ((item.social_url.includes('twitter') || item.social_url.includes('x.com'))  ?
                                                                <TwitterEmbed url={item.social_url} /> :
                                                                (item.social_url.includes('tiktok') ?
                                                                    <TikTokEmbed url={item.social_url} /> :
                                                                    (item.social_url.includes('linked') ?
                                                                        <LinkedInEmbed url={item.social_url} /> : ''
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }

                {item.type === FeedItemType.PORTFOLIO &&
                    <>
                        <p>
                            <strong> Portfolio Type "{item.purpose}" </strong>
                        </p>
                        <p style={{ whiteSpace: 'pre-line' }}>{item.reason}</p>
                        <div className="text-center">
                            <div className="row">
                                <div className="col-md-12 col-12" style={{
                                    height: '250px',
                                    maxHeight: '250px',
                                    marginBottom: '50px'
                                }}>
                                    <PieChart animate={true}
                                        label={({ dataEntry }) => dataEntry.title}
                                        labelStyle={{
                                            fill: 'white',
                                            fontSize: '5px',
                                            fontFamily: 'sans-serif',
                                        }}
                                        data={
                                            // [
                                            //     { title: 'One', value: 100, color: '#E38627' },
                                            //     { title: 'Two', value: 15, color: '#C13C37' },
                                            //     { title: 'Three', value: 20, color: '#6A2135' },
                                            //   ]
                                            item.tickers.map((tkr, index) => {
                                                const obj = { title: tkr.ticker, value: Math.trunc(+tkr.currentprice), color: randomColor(index) };
                                                return obj
                                            })
                                        }
                                        segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                                        // lineWidth={65}
                                        // segmentsShift={1}
                                        // labelPosition={100 - 65 / 2}
                                        labelPosition={75}
                                    />
                                </div>
                            </div>
                            <div className="row table-responsive">
                                <div className="col-md-12 col-12">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Company Name</th>
                                                <th>Ticker</th>
                                                <th>Current Price</th>
                                                <th>52 Week Low</th>
                                                <th>52 Week High</th>
                                                <th>Maret Cap</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {item.tickers.map((tkr, index) => (
                                                <tr key={index}>
                                                    <td>{tkr.companyName}</td>
                                                    <td>{tkr.ticker}</td>
                                                    <td>${tkr.currentprice}</td>
                                                    <td>${tkr.wklow}</td>
                                                    <td>${tkr.wkhigh}</td>
                                                    <td>${tkr.marketCap}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <p>Average Price when created: <b>${roundNumber(item.portfolioCreatedPrice)}</b></p>
                        <p>Current Average Price: <b>${roundNumber(item.currentPrice)}</b></p>
                    </>
                }
                <div className="py-2 pb-3 small">
                    {
                        item.type === FeedItemType.QUESTION && (
                            <span className="me-3" role="button" onClick={() => triggerShowComment()}>
                                <i className="fas fa-reply"></i> {item[ItemKeyMap[item.type]['replyCount']] || '0'} Reply
                            </span>
                        )
                    }
                    {
                        item.type === FeedItemType.TOPIC && (
                            <>
                                <span className="me-3 me-md-5" role="button" onClick={() => topicAgreeDisagree(item.id, TopicFlags.AGREE)}>
                                    <i className="far fa-thumbs-up"></i> {item[ItemKeyMap[item.type]['agreeCount']] || '0'} Agree
                                </span>
                                <span className="mx-3 mx-md-5" role="button" onClick={() => topicAgreeDisagree(item.id, TopicFlags.DISAGREE)}>
                                    <i className="far fa-thumbs-down"></i> {item[ItemKeyMap[item.type]['disagreeCount']] || '0'} Disagree
                                </span>
                                <span className="mx-3 mx-md-5" role="button" onClick={() => triggerShowComment()}>
                                    <i className="fas fa-reply"></i> {item[ItemKeyMap[item.type]['replyCount']] || '0'} Reply
                                </span>
                            </>
                        )
                    }
                    {
                        item.type === FeedItemType.PORTFOLIO && (
                            <>
                                <span className="me-3 me-md-4" role="button" onClick={() => portfolioReccDontRecc(PortfolioFlag.RECOMMEND)}>
                                    <i className="far fa-thumbs-up"></i> {item[ItemKeyMap[item.type]['agreeCount']] || '0'}
                                    <span className="d-md-inline-block d-none">
                                        &nbsp; Recommend
                                    </span>
                                </span>
                                <span className="mx-3 mx-md-4" role="button" onClick={() => portfolioReccDontRecc(PortfolioFlag.DONT_RECOMMEND)}>
                                    <i className="far fa-thumbs-down"></i> {item[ItemKeyMap[item.type]['disagreeCount']] || '0'}
                                    <span className="d-md-inline-block d-none">
                                        &nbsp; Not Recommended
                                    </span>
                                </span>
                                <span className="mx-3 mx-md-4" role="button" onClick={portfolioHold}>
                                    <i className="far fa-hand-paper"></i> {item[ItemKeyMap[item.type]['holdCount']] || '0'}
                                    <span className="d-md-inline-block d-none">
                                        &nbsp; Hold
                                    </span>
                                </span>
                                <span className="mx-3 mx-md-4" onClick={() => triggerShowComment()} role="button">
                                    <i className="fas fa-reply"></i> {item[ItemKeyMap[item.type]['replyCount']] || '0'}
                                    <span className="d-md-inline-block d-none">
                                        &nbsp; Comment
                                    </span>
                                </span>
                            </>
                        )
                    }
                </div>
                <div className="sj-comment-reply-container">
                    {
                        session?.user && showNewComment && <>
                            <div className="sj-comment-reply">
                                <NewComment
                                    state={state}
                                    setState={setState}
                                    post={async (summary) => {
                                        await addReply(item, summary);
                                        triggerShowComment(false);
                                    }}
                                ></NewComment>
                                <hr />
                            </div>
                        </>
                    }
                    {
                        (item.topicreplys || item.questionreplys || item.protfolioreply || []).slice(0, standaloneView ? undefined : 2).map((comment, index) => (
                            <Comments
                                state={state}
                                setState={setState}
                                key={comment.id}
                                comment={comment}
                                type={item.type}
                                showNested={!!standaloneView}
                                commentLike={commentLike}
                                commentReplyLike={commentReplyLike}
                                // showNewComment={ showNewComment && index === 0 }
                                // postReply={(summary) => {
                                //     triggerShowComment(false);
                                //     addReply(item, summary)
                                // }}
                                postReplyToReply={(comment, summary) => addReplyToReply(comment, summary)}
                                fullLink={itemLink}
                            ></Comments>
                        ))
                    }
                </div>
            </div>
            <hr />
        </>
    )
}

export default FeedItem
