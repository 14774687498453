function About() {
    return (
        <div>
            We are StockJabber Team who are on a mission to provide unique centralized experience about Stocks, Finance Topics, Finance Questions and Market news all at one place. We are also committed to provide a platform for Groups to discuss Market, Stocks and related Financial information. Below detailed description about StockJabber. Stock Jabber Website and App is owned by <b>Datacent</b> If we upset you at any point of StockJabber experience, please do not hesitate to write Feedback to us in the Profile Page.
            If we upset you at any point of StockJabber experience, please do not hesitate to write Feedback to us in the Profile Page.
            
            <p className="h5 mt-4" style={{color: '#000099'}}>
                Detailed Description:
            </p>
            <p>
                Stock Jabber provides platform to discuss about Topics, raise Questions and get answers from other users. Stock Jabber also provide platform for discussing latest news, trending market information about individual Stocks. This is the unique place where users can submit Portfolio of their stocks as a post and get recommendation from other users. There also other features, users can read stock news and read expert articles about Finance and Market. 
                Below are detailed features under this app:
            </p>
            <p className="h5 mt-4" style={{color: '#000099'}}>Topics:</p> You can view, post topic and get other people opinion on Topics
            <p className="h5 mt-4" style={{color: '#000099'}}>Questions:</p> You can view, raise Questions and get answers from other users
            <p className="h5 mt-4" style={{color: '#000099'}}>Portfolio:</p> You can view and submit their Portfolio of stocks and get recommendation from other users
            <p className="h5 mt-4" style={{color: '#000099'}}>Individual Stocks:</p> You can see latest news, Trending market news on Individual stocks. Also post Individual stocks information and get other people opinion
            <p className="h5 mt-4" style={{color: '#000099'}}>Articles:</p> You can read Finance articles from experts.
        </div>
    )
}

export default About
