import React from 'react'
import { kebabCase } from 'lodash';
import { BaseProp, FeedItemType } from '../../model/BaseResource.model'
import "./StocksUpDown.scss";
import { Link } from 'react-router-dom';
import { getBaseHref } from "../../url-base-href-ts";

// const BASE_HREF = 'https://api.stockjabber.com:8443/dev/sj';
 const BASE_HREF = getBaseHref();

export interface StocksUpDownProps extends BaseProp {

}

function StocksUpDown({ item }: StocksUpDownProps ) {
    const itemLink = `item/${item.type}/${item.id}/trending/${kebabCase(item.title)}`.toLocaleLowerCase();
    
    return (
        <div className="d-flex pb-1 pl-1">
            <div className="mx-1"> 
                <div className="d-flex flex-column">
                {/* <img src="https://api.stockjabber.com:8443/dev/sj/image_catalog/imageURL/131" className='image-fluid' style={{ maxWidth: '100%',  maxHeight:'100%'}}></img> */}
                <img src={BASE_HREF + item.image_url.replace('\sj','')} className='image-fluid' style={{ maxWidth: '90%',  maxHeight:'28%'}}></img>
                <Link to={{ pathname: `/${itemLink}`, state: { item }}} target="_blank" className="title-link">
                    <h6 className="fw-bold mt-1">
                        {item.title?.replace(/\n/g, ' ')}
                    </h6>
                </Link>
                </div>
                {/* <div>
                    <p className="mb-1 trending-item-summary">
                        {item.summary?.replace(/\n/g, ' ').substring(0, 150)}
                    </p>
                </div> */}
            </div>
        </div>
    )
}

export default StocksUpDown;
