import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import "./Header.scss";

const Header = ({ state, setState }: any) => {
    const { session } = state;
    const nav = useHistory();

    const doLogout = () => {
        session.user = null;
        setState({ ...state, ...session })
    }

    const apple = /iPhone|iPad|iPod/.test(navigator.userAgent)

    useEffect(() => {
        const handler = (e) => {
            try {
                const navbar = document.querySelector('#headerSecondaryNavBar');
                if (navbar && navbar.classList.contains('show'))
                    (document.querySelector('[data-bs-target="#headerSecondaryNavBar"]') as any).click();
            } catch (ee) { }
        }

        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        }
    }, []);

    return (
        <>
            <Navbar collapseOnSelect expand="lg" sticky="top">
                <Container fluid>
                    <Navbar.Brand>
                        <div className="d-flex align-items-center" role="button" onClick={() => nav.push('/')}>
                            <img
                                alt=""
                                src={process.env.PUBLIC_URL + `/images/JS2-02.png`}  //TODO.. public_url
                                width="65"
                                height="65"
                                className="d-inline-block align-top mx-1 img-fluid"
                            />
                            <span className="logo-title">
                                <span className="logo-title-green">Stock</span>
                                <span>Jabber</span><br />
                                <span className="d-none d-md-block" style={{ fontSize: '12px', float: 'left', color: 'black', fontStyle: 'italic' }}>Follow, Learn, Talk and Share about Finance</span>
                            </span>
                        </div>
                    </Navbar.Brand>
                    {/* {session?.user &&  */}         {/* This toggles secondary navbar, using HTML bootstrap instead of React Component (LN: 58) */}
                    <span className="d-md-none d-sm-block d-inline-block">
                        <span className='d-inline-block' style={{display:'inline-flex'}}>
                            {/* <button className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#headerSecondaryNavBar">
                                <span className="navbar-toggler-icon"></span></button> */}
                          {session?.user?.firstName && 
                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src={process.env.PUBLIC_URL + `/images/user-profile.png`} className="image-avatar-user-profile img-fluid" />  <small>{session?.user?.firstName}</small>
                            <Nav.Link onClick={doLogout}> <small>Logout</small> <img src={process.env.PUBLIC_URL + `/images/logOut.png`} className="image-avatar-user-profile img-fluid" />
                            </Nav.Link>
                            </>
                            }
                            
                            {!session?.user && <LinkContainer to={'/login'}>
                                <Nav.Link>
                                    Login
                                </Nav.Link>
                            </LinkContainer>
                            }

                        </span>
                    </span>
                    {/* {session?.user && <div className="d-none d-md-flex align-items-center justify-content-between" style={{paddingRight: '10%'}}>
                        <LinkContainer to="/">
                            <Nav.Link>
                                <p style={{ color: 'black', marginTop: '10px' }}><img className="nav-icon" src={`${process.env.PUBLIC_URL}/images/header-feed.png`} alt="Home Feed" />
                                    Feed</p>
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/">
                            <Nav.Link>
                                <p style={{ color: 'black', marginTop: '10px', opacity: '.5' }}><img className="nav-icon" src={`${process.env.PUBLIC_URL}/images/header-stocks.png`} alt="Home Feed" />
                                    Stocks</p>
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/">
                            <Nav.Link>
                                <p style={{ color: 'black', marginTop: '10px', opacity: '.5' }}><img className="nav-icon" src={`${process.env.PUBLIC_URL}/images/header-crypto.png`} alt="Home Feed" />
                                    Crypto</p>
                            </Nav.Link>
                        </LinkContainer>
                    </div>
                    } */}
                    <span className="d-none d-md-block">
                        <div className="d-lg-flex justify-content-end d-inline-block">
                            <Nav className="d-flex align-items-end">
                                <Nav.Link className="m-0 d-flex flex-column align-items-center">
                                    {
                                        session?.user?.firstName && <>
                                            <img src={process.env.PUBLIC_URL + `/images/user-profile.png`} className="image-avatar-user-profile" />
                                            <small>{session?.user?.firstName}</small>
                                        </>
                                    }
                                </Nav.Link>
                                {session?.user && <Nav.Link className="m-0" onClick={doLogout}>
                                    &nbsp; <img src={process.env.PUBLIC_URL + `/images/logOut.png`} className="image-avatar-user-profile" /> <br />
                                    <small>Logout</small>
                                </Nav.Link>
                                }
                                {!session?.user && <LinkContainer to={'/login'}>
                                    <Nav.Link className="m-0">
                                        Login
                                    </Nav.Link>
                                </LinkContainer>
                                }
                            </Nav>
                        </div>
                    </span>
                </Container>
            </Navbar>
            <Navbar fixed="bottom" className="d-block d-lg-none">
                <Nav className="mx-auto justify-content-center">
                    {<Nav.Link href="https://apps.apple.com/us/app/stock-jabber/id1587176123" target="_blank">
                        <img className="app-store-icon"
                            src={`${process.env.PUBLIC_URL}/images/ios.png`}  //TODO fix the public_url prefix
                            alt="Apple App Store" />
                    </Nav.Link>}
                    {!apple && <Nav.Link href="https://play.google.com/store/apps/details?id=com.datacent.stockjabber" target="_blank">
                        <img className="app-store-icon"
                            src={`${process.env.PUBLIC_URL}/images/android.png`}  // TODO fix the public_url prefix
                            alt="Google Play Store" />
                    </Nav.Link>}
                </Nav>
            </Navbar>
            <Navbar expand="md" className="sj-fixed-md w-100">
                <Container>
                    {/* This NavBar.collpase is toggled by button in main navbar */}
                    <Navbar.Collapse className="mx-auto justify-content-around" id="headerSecondaryNavBar">
                        <Nav>
                            {/* <div className="d-block">
                                <Nav.Link className="">
                                {
                                        session?.user?.firstName && <>
                                            Hi, { session?.user?.firstName }
                                        </>
                                    }
                                </Nav.Link>
                                { session?.user && 
                                        <Nav.Link onClick={doLogout}>
                                            Logout
                                        </Nav.Link>
                                }
                                { !session?.user && <LinkContainer to={'/login'}>
                                        <Nav.Link>
                                            Login
                                        </Nav.Link>
                                    </LinkContainer>
                                }
                            </div> */}
                            {/* 
                            <LinkContainer to="/">
                                <Nav.Link>
                                    Article
                                </Nav.Link>
                            </LinkContainer>
                            */}
                            {/* <LinkContainer to="/">
                                <Nav.Link>
                                    IPO Analysis
                                </Nav.Link>
                            </LinkContainer> */}
                            {/* <LinkContainer to="/privacy-policy">
                                <Nav.Link>
                                    Privacy Notice
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/terms-of-use">
                                <Nav.Link>
                                    Terms of Use
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/about">
                                <Nav.Link>
                                    About Us
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/contact">
                                <Nav.Link>
                                    Contact Us
                                </Nav.Link>
                            </LinkContainer> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header
