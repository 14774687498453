import { BaseItem } from "../services/HomePage.model"
import { SessionService } from "../services/Session.service"

export interface IInjector {
    session: SessionService;
    [key: string]: any;
}

export interface BaseProp {
    state: IInjector;
    setState: Function;
    item?: BaseItem;
    [key: string]: any;
}

export enum FeedItemType {
    QUESTION = "Question",
    TOPIC = "Topic",
    PORTFOLIO = "Portfolio"
}

export interface BaseResource {
    id: number;
    timedifference: string;
}

export interface BaseReply extends BaseResource {
    // likeCount: number;
    replyCommentcount: number;
    reply: any[];
}

export interface TopicReply extends BaseReply {
    likecount: number;
    topicReplySummary: string;
    topicReplydby: string;
    topicReplydate: string;
    /**
     * deprecated
     */
    topicLike: number | null;
    topicId: number;
}

export interface QuestionReply extends BaseReply {
    questionReplySummary: string;
    questionReplydby: string;
    questionReplydate: string;
    questionHelpful: number | null;
    questionId: number;
    reply: QuestionCommentReply[]
}

export interface QuestionCommentReply extends BaseResource {
    questionReplyId: number;
    questionCommentHelpful: number | null;
    questionReplyCommentSummary: string;
    questionReplyCommentdate: string;
    questionReplyCommentdby: string;
}

export interface PortfolioReply extends BaseReply {
    likecount: number;
    replysummary: string;
    portfolioreplyby: string;
    portfolioreplydate: string;
    portfolioId: number;
}

export enum TopicFlags {
    AGREE = "agree",
    DISAGREE = "disagree"
}

export enum PortfolioFlag {
    RECOMMEND = "recommend",
    DONT_RECOMMEND = "dontrecommend"
}

export interface TopicAgreeDisagree {
    topicId: number;
    topicReplyby: number;
    flag: TopicFlags
}

export const CommentKeyMap = {
    [FeedItemType.TOPIC]: {
        replyBy:      'topicReplydby',
        replyDate:    'topicReplydate',
        replySummary: 'topicReplySummary',
        likeCount:    'likecount'
    },
    [FeedItemType.QUESTION]: {
        replyBy:      'questionReplydby',
        replyDate :   'questionReplydate',
        replySummary: 'questionReplySummary',
        likeCount:    'questionHelpful'
    },
    [FeedItemType.PORTFOLIO]: {
        replyBy:      'portfolioreplyby',
        replyDate:    'portfolioreplydate',
        replySummary: 'replysummary',
        likeCount:    'likecount'
    },
}

export const CommentReplyKeyMap = {
    [FeedItemType.TOPIC]: {
        'replyBy':      'topicReplyCommentdby',
        'replyDate':    'topicReplyCommentdate',
        'replySummary': 'topicReplyCommentSummary'
    },
    [FeedItemType.QUESTION]: {
        'replyBy':      'questionReplyCommentdby',
        'replyDate' :   'questionReplyCommentdate',
        'replySummary': 'questionReplyCommentSummary'
    },
    [FeedItemType.PORTFOLIO]: {
        'replyBy':      'portfolioReplyCommentby',
        'replyDate':    'portfolioReplyCommentdate',
        'replySummary': 'portfolioReplyComment'
    },
}

export const ItemKeyMap = {
    [FeedItemType.TOPIC]: {
        'replyCount': 'replycount',
        'agreeCount': 'topicagreecount',
        'disagreeCount': 'topicdisagreecount',
        'holdCount': ''
    },
    [FeedItemType.QUESTION]: {
        'replyCount': 'replycount',
        'agreeCount': '',
        'disagreeCount': '',
        'holdCount': ''
    },
    [FeedItemType.PORTFOLIO]: {
        'replyCount': 'replycount',
        'agreeCount': 'portfoliRecommendCount',
        'disagreeCount': 'portfoliDontRecommendCount',
        'holdCount': 'portfolioHoldCount'
    }
}