import React, { useEffect, useState } from 'react'
import { FormGroup, FloatingLabel, Form, Button } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead';
import { useHistory } from 'react-router';
import { getAllTickers, postCreatePortfolio, postPreviewPortfolio } from '../../services/RepoService';
import { redirectIfNotLoggedIn } from '../../services/Session.service';
import FeedItem from '../FeedItem/FeedItem';
import "./NewPortfolio.scss";

function NewPortfolio({ state, setState}: any) {
    const nav = useHistory();
    const { session } = state;
    const [showHelp, setShowHelp] = useState(false);
    const [selectedTickers, setSelectedTickers] = useState([]);
    const [tickers, setTickers] = useState([]);
    const [preview, setPreview] = useState(undefined);

    const [portfolio, setPortfolio] = useState({
        portfolio_name: '',
        tickers: '',
        purpose: '',
        reason: ''
    });

    useEffect(() => {
        (async () => {
            try {
                let tkrs = await getAllTickers() as any;
                setTickers(tkrs.map(t => {t['label'] = t.tag + '  (' + t.ticker + ')'; return t;}));
            } catch (e){
                console.error('Unable to get tickers', e);
            }
        })();
    }, []);

    const addTickerToModel = () => {
        return { ...portfolio, tickers: selectedTickers.map(t => t.ticker).join(',')};
    }

    const checkLogin = () => {
        try { 
            return redirectIfNotLoggedIn(session, nav);
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    const checkModel = (model) => {
        if (model && model.portfolio_name && model.purpose && model.reason && model.tickers)
            return true;
        else {
            alert('Please enter all data');
            return false;
        }
    }

    const previewPortfolio = async () => {
        if (!checkLogin()) return; 
        const model = addTickerToModel();
        if (!checkModel(model)) return; 

        try {
            const item = await postPreviewPortfolio(model, session.user.id);
            setPreview(item);
        } catch (e) {
            alert('There was an error');
            console.error(e);
        }
    }

    const submitPortfolio = async () => {
        if (!checkLogin()) return; 
        const model = addTickerToModel();
        if (!checkModel(model)) return; 
        
        try {
            const item = await postCreatePortfolio(model, session.user.id);
            if (item) {
                nav.push('/');
            } else {
                console.log(item);
            }
        } catch (e) {
            alert('There was an error');
            console.error(e);
        }
    }

    return (
        <>
        <h4 className="text-center">Create your Desired Portfolio</h4>
            <div>
                This is a powerful feature to create your desired Porfolio of stocks, 
                post and get other users' opinion. 
                Based on other users' suggestions, you can delete, 
                add certain stocks to construct a Healthly Portfolio for better Financial returns. 
            </div>
            <div className="row">
            <div className="col-md-4">
                <br/>
                <br/>
            <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/NewPortfolio.jpg`} />
                    </div>
                    <div className="col-md-8">
                    <div className="porfolio-container">
            <br />
            <h6 style={{textAlign:'center'}}>How it Works: <i role="button" className="fas fa-info-circle" onClick={ () => setShowHelp(!showHelp) } ></i> </h6> 
            { showHelp && 
                <ol>
                    <li>Give your Portfolio Name.</li>
                    <li>Select Purpose such as Retirement Portfolio, Long Term 10 Years, Long Term 7 Years, 
                        Long Term 5 Years, Long Term 3 Years, Long Term 2 Years, Long Term 1 Year, Short Term,
                        Dividend, Value, Growth, Cyclical or Others. 
                    </li>
                    <li>Select Stocks by typing Tickers or Names.</li>
                    <li>Provide Reason for creating this Portfolio, meaning your detailed plan for selecting these stocks.</li>
                    <li>Preview before submitting to see how it looks like.</li>
                    <li>Submit to post and get other Users opinion.</li>
                </ol>
            }
            <div className="new-item-portfolio-wrapper">
                <FormGroup className="mb-3">
                        <Form.Control type="text" placeholder="Portfolio Name" required className="p-2" 
                            onChange={(e) => setPortfolio({ ...portfolio, portfolio_name: e.target.value}) }
                        />
                </FormGroup>

                <FormGroup className="mb-3">
                        <Form.Select className="p-2" placeholder="Portfolio Type" onChange={(e) => 
                                setPortfolio({ ...portfolio, purpose: (e.target as any).value})
                            }
                            >
                            <option value="" selected disabled>Portfolio Type</option>
                            <option value="Retirement Portfolio"> Retirement Portfolio </option>
                            <option value="Long Term 10 Years"> Long Term 10 Years </option>
                            <option value="Long Term 7 Years"> Long Term 7 Years </option>
                            <option value="Long Term 5 Years"> Long Term 5 Years </option>
                            <option value="Long Term 3 Years"> Long Term 3 Years </option>
                            <option value="Long Term 2 Years "> Long Term 2 Years  </option>
                            <option value="Long Term 1 Year "> Long Term 1 Year  </option>
                            <option value="Short Term"> Short Term </option>
                            <option value="Dividend "> Dividend  </option>
                            <option value="Value "> Value  </option>
                            <option value="Growth "> Growth  </option>
                            <option value="Cyclical "> Cyclical  </option>
                            <option value="Others"> Others </option>
                        </Form.Select>
                </FormGroup>

                <FormGroup className="mb-3">
                    <Typeahead
                        id="portfolio-tickers"
                        labelKey="label"
                        multiple
                        onChange={setSelectedTickers}
                        options={tickers}
                        // @ts-ignore
                        placeholder="Select tickers"
                        selected={selectedTickers}
                    />
                </FormGroup>

                <FormGroup className="mb-3">
                        <Form.Control as="textarea" style={{ height: '100px' }} 
                            placeholder="Reason" required className="p-2" 
                            onChange={(e) => setPortfolio({ ...portfolio, reason: (e.target as any).value})}
                        />
                </FormGroup>

                <div className="button-wrapper">
                    <Button variant="dark" type="button" onClick={previewPortfolio} >
                        Preview
                    </Button>
                    <Button variant="dark" type="button" onClick={submitPortfolio}>
                        Submit
                    </Button>
                </div>
            </div>
            {
                preview && <>
                    <hr />
                    <FeedItem state={state} setState={setState} item={preview} updateItem={() => null}/>
                </>
            }
        </div>
        </div>
        </div>
        </>
    )
}

export default NewPortfolio
