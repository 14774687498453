function Contact() {
    return (
        <div>
            <p className="h5 my-4" style={{color: '#000099'}}>Get in Touch with us:</p>
            For any complaints or improvements on app, please use Feedback page and submit your Opinion. We consider your Opinion as valuable and take it very seriously and will work on it as soon as we can. Please contact us at our email <b>information@stockjabber.com</b> for any enquiries.
        </div>
    )
}

export default Contact
