import { useEffect, useState } from 'react';

function Privacy() {
    return (
        <>
        <div style={{maxWidth:'90%', paddingLeft:'5%'}}>
        <p><strong>PRIVACY NOTICE</strong></p>
        <p><strong>Last updated September 01, 2022</strong></p>
        <p>
        Thank you for choosing to be part of our community at Stock Jabber (&quot;
        <strong>we</strong>&quot;, &quot; <strong>us</strong>&quot;, &quot;
        <strong>our</strong>&quot;). We Stock Jabber owned by Datacent, are 
        committed to protecting your personal information and your right
        to privacy. If you have any questions or concerns about this privacy notice,
        or our practices with regards to your personal information, please contact us
        at
        <a href="mailto:information@stockjabber.com">information@stockjabber.com</a>.
        </p>
        <p>
        When you visit our website
        <a href="http://www.stockjabber.com/">http://www.stockjabber.com</a> (the
        &quot; <strong>Website</strong>&quot;), use our mobile application, as the
        case may be (the &quot; <strong>App</strong>&quot;) and more generally, use
        any of our services (the &quot; <strong>Services</strong>&quot;, which include
        the Website and App), we appreciate that you are trusting us with your
        personal information. We take your privacy very seriously. In this privacy
        notice, we seek to explain to you in the clearest way possible what
        information we collect, how we use it and what rights you have in relation to
        it. We hope you take some time to read through it carefully, as it is
        important. If there are any terms in this privacy notice that you do not agree
        with, please discontinue use of our Services immediately.
        </p>
        <p>
        This privacy notice applies to all information collected through our Services
        (which, as described above, includes our Website and App), as well as any
        related services, sales, marketing or events.
        </p>
        <p>
        <strong
            >Please read this privacy notice carefully as it will help you understand
            what we do with the information that we collect.</strong
        >
        </p>
        <p><strong>TABLE OF CONTENTS</strong></p>
        {/* <p>
        <a href="#1-WHAT-INFORMATION-DO-WE-COLLECT">1. WHAT INFORMATION DO WE COLLECT?</a>
        </p>
        <p>
        <a href="#2-HOW-DO-WE-USE-YOUR-INFORMATION">2. HOW DO WE USE YOUR INFORMATION?</a>
        </p>
        <p>
        <a href="#3-WILL-YOUR-INFORMATION-BE-SHARED-WITH-ANYONE">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
        </p>
        <p>
        <a href="#4-DO-WE-USE-COOKIES-AND-OTHER-TRACKING-TECHNOLOGIES">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES</a>
        </p>
        <p>
        <a href="#5-HOW-DO-WE-HANDLE-YOUR-SOCIAL-LOGINS">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
        </p>
        <p>
        <a href="#6-IS-YOUR-INFORMATION-TRANSFERRED-INTERNATIONALL">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</a>
        </p>
        <p>
        <a href="#7-WHAT-IS-OUR-STANCE-ON-THIRD-PARTY-WEBSITES">7. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</a>
        </p>
        <p>
        <a href="#8-HOW-LONG-DO-WE-KEEP-YOUR-INFORMATION">8. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
        </p>
        <p>
        <a href="#9-HOW-DO-WE-KEEP-YOUR-INFORMATION-SAFE">9. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
        </p>
        <p>
        <a href="#10-DO-WE-COLLECT-INFORMATION-FROM-MINORS">10. DO WE COLLECT INFORMATION FROM MINORS?</a>
        </p>
        <p>
        <a href="#11-WHAT-ARE-YOUR-PRIVACY-RIGHTS">11. WHAT ARE YOUR PRIVACY RIGHTS?</a>
        </p>
        <p>
        <a href="#12-CONTROLS-FOR-DO-NOT-TRACK-FEATURE">12. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
        </p>
        <p>
        <a href="#13-DO-CALIFORNIA-RESIDENTS-HAVE-SPECIFIC-PRIVACY-RIGHTS">13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
        </p>
        <p>
        <a href="#14-DO-WE-MAKE-UPDATES-TO-THIS-NOTICE">14. DO WE MAKE UPDATES TO THIS NOTICE?</a>
        </p>
        <p>
        <a href="#15-HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE">15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
        </p>
        <p>
        <a href="">16. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</a>
        </p> */}
        <p>
        <a>1. WHAT INFORMATION DO WE COLLECT?</a>
        </p>
        <p>
        <a>2. HOW DO WE USE YOUR INFORMATION?</a>
        </p>
        <p>
        <a>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
        </p>
        <p>
        <a>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES</a>
        </p>
        <p>
        <a>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
        </p>
        <p>
        <a>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</a>
        </p>
        <p>
        <a>7. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</a>
        </p>
        <p>
        <a>8. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
        </p>
        <p>
        <a>9. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
        </p>
        <p>
        <a>10. DO WE COLLECT INFORMATION FROM MINORS?</a>
        </p>
        <p>
        <a>11. WHAT ARE YOUR PRIVACY RIGHTS?</a>
        </p>
        <p>
        <a>12. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
        </p>
        <p>
        <a>13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
        </p>
        <p>
        <a>14. DO WE MAKE UPDATES TO THIS NOTICE?</a>
        </p>
        <p>
        <a>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
        </p>
        <p>
        <a>16. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</a>
        </p>
        <hr />
        <p><a id="1-WHAT-INFORMATION-DO-WE-COLLECT"></a></p>
        <h3 id="1-what-information-do-we-collect-">
        1. WHAT INFORMATION DO WE COLLECT?
        </h3>
        <p><strong>Information automatically collected</strong></p>
        <p>
        <em
            ><strong>In Short</strong>: Some information — such as your Internet
            Protocol (IP) address and/or browser and device characteristics — is
            collected automatically when you visit our Services.</em
        >
        </p>
        <p>
        We automatically collect certain information when you visit, use or navigate
        the Services. This information does not reveal your specific identity (like
        your name or contact information) but may include device and usage
        information, such as your IP address, browser and device characteristics,
        operating system, language preferences, referring URLs, device name, country,
        location, information about how and when you use our Services and other
        technical information. This information is primarily needed to maintain the
        security and operation of our Services, and for our internal analytics and
        reporting purposes.
        </p>
        <p>
        Like many businesses, we also collect information through cookies and similar
        technologies.
        </p>
        <p>The information we collect includes:</p>
        <ul>
        <li>
            <p>
            <em>Log and Usage Data.</em> Log and usage data is service-related,
            diagnostic, usage and performance information our servers automatically
            collect when you access or use our Services and which we record in log
            files. Depending on how you interact with us, this log data may include
            your IP address, device information, browser type and settings and
            information about your activity in the Services (such as the date/time
            stamps associated with your usage, pages and files viewed, searches and
            other actions you take such as which features you use), device event
            information (such as system activity, error reports (sometimes called
            &#39;crash dumps&#39;) and hardware settings).
            </p>
        </li>
        <li>
            <p>
            <em>Device Data.</em> We collect device data such as information about
            your computer, phone, tablet or other device you use to access the
            Services. Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware model
            Internet service provider and/or mobile carrier, operating system and
            system configuration information.
            </p>
        </li>
        <li>
            <p>
            <em>Location Data.</em> We collect location data such as information about
            your device&#39;s location, which can be either precise or imprecise. How
            much information we collect depends on the type and settings of the device
            you use to access the Services. For example, we may use GPS and other
            technologies to collect geolocation data that tells us your current
            location (based on your IP address). You can opt out of allowing us to
            collect this information either by refusing access to the information or
            by disabling your Location setting on your device. Note however, if you
            choose to opt out, you may not be able to use certain aspects of the
            Services.
            </p>
        </li>
        </ul>
        <p><strong>Information collected through our App</strong></p>
        <p>
        <em
            ><strong>In Short:</strong> We collect information regarding your mobile
            device, push notifications, when you use our App.</em
        >
        </p>
        <p>If you use our App, we also collect the following information:</p>
        <ul>
        <li>
            <p>
            <em>Mobile Device Data.</em> We automatically collect device information
            (such as your mobile device ID, model and manufacturer), operating system,
            version information and system configuration information, device and
            application identification numbers, browser type and version, hardware
            model Internet service provider and/or mobile carrier, and Internet
            Protocol (IP) address (or proxy server). If you are using our App, we may
            also collect information about the phone network associated with your
            mobile device, your mobile device&#39;s operating system or platform, the
            type of mobile device you use, your mobile device&#39;s unique device ID
            and information about the features of our App you accessed.
            </p>
        </li>
        <li>
            <p>
            <em>Push Notifications.</em> We may request to send you push notifications
            regarding your account or certain features of the App. If you wish to
            opt-out from receiving these types of communications, you may turn them
            off in your device&#39;s settings.
            </p>
        </li>
        </ul>
        <p>
        This information is primarily needed to maintain the security and operation of
        our App, for troubleshooting and for our internal analytics and reporting
        purposes.
        </p>
        <p><a id="2-HOW-DO-WE-USE-YOUR-INFORMATION"></a></p>
        <h3 id="2-how-do-we-use-your-information-">
        2. HOW DO WE USE YOUR INFORMATION?
        </h3>
        <p>
        <em
            ><strong>In Short:</strong> We process your information for purposes based
            on legitimate business interests, the fulfillment of our contract with you,
            compliance with our legal obligations, and/or your consent.</em
        >
        </p>
        <p>
        We use personal information collected via our Services for a variety of
        business purposes described below. We process your personal information for
        these purposes in reliance on our legitimate business interests, in order to
        enter into or perform a contract with you, with your consent, and/or for
        compliance with our legal obligations. We indicate the specific processing
        grounds we rely on next to each purpose listed below.
        </p>
        <p>We use the information we collect or receive:</p>
        <ul>
        <li>
            <p>
            <strong>To facilitate account creation and logon process.</strong> If you
            choose to link your account with us to a third-party account (such as your
            Google or Facebook account), we use the information you allowed us to
            collect from those third parties to facilitate account creation and logon
            process for the performance of the contract. See the section below headed
            &quot;<a href="#5-HOW-DO-WE-HANDLE-YOUR-SOCIAL-LOGINS?"
                >HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a
            >&quot; for further informatio.
            </p>
        </li>
        <li>
            <p>
            <strong>To post testimonials.</strong> We post testimonials on our
            Services that may contain personal information. Prior to posting a
            testimonial, we will obtain your consent to use your name and the content
            of the testimonial. If you wish to update, or delete your testimonial,
            please contact us at information@stockjabber.com and be sure to include
            your name, testimonial location, and contact information.
            </p>
        </li>
        <li>
            <p>
            <strong>Request feedback.</strong> We may use your information to request
            feedback and to contact you about your use of our Services.
            </p>
        </li>
        <li>
            <p>
            <strong>To enable user-to-user communications.</strong> We may use your
            information in order to enable user-to-user communications with each
            user&#39;s consent.
            </p>
        </li>
        <li>
            <p>
            <strong>To manage user accounts.</strong> We may use your information for
            the purposes of managing our account and keeping it in working order.
            </p>
        </li>
        <li>
            <p>
            <strong>To send administrative information to you.</strong> We may use
            your personal information to send you product, service and new feature
            information and/or information about changes to our terms, conditions, and
            policies.
            </p>
        </li>
        <li>
            <p>
            <strong>To protect our Services.</strong> We may use your information as
            part of our efforts to keep our Services safe and secure (for example, for
            fraud monitoring and prevention).
            </p>
        </li>
        <li>
            <p>
            <strong
                >To enforce our terms, conditions and policies for business purposes, to
                comply with legal and regulatory requirements or in connection with our
                contract.</strong
            >
            </p>
        </li>
        <li>
            <p>
            <strong>To respond to legal requests and prevent harm.</strong> If we
            receive a subpoena or other legal request, we may need to inspect the data
            we hold to determine how to respond.
            </p>
        </li>
        <li>
            <p>
            <strong>To send you marketing and promotional communications.</strong> We
            and/or our third-party marketing partners may use the personal information
            you send to us for our marketing purposes, if this is in accordance with
            your marketing preferences. For example, when expressing an interest in
            obtaining information about us or our Services, subscribing to marketing
            or otherwise contacting us, we will collect personal information from you.
            You can opt-out of our marketing emails at any time (see the &quot;<a
                href="#11-WHAT-ARE-YOUR-PRIVACY-RIGHTS?"
                >WHAT ARE YOUR PRIVACY RIGHTS?</a
            >&quot; below.
            </p>
        </li>
        <li>
            <p>
            <strong>Deliver targeted advertising to you.</strong> We may use your
            information to develop and display personalized content and advertising
            (and work with third parties who do so) tailored to your interests and/or
            location and to measure its effectiveness.
            </p>
        </li>
        <li>
            <p>
            <strong>For other business purposes.</strong> We may use your information
            for other business purposes, such as data analysis, identifying usage
            trends, determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Services, products, marketing and your
            experience. We may use and store this information in aggregated and
            anonymized form so that it is not associated with individual end users and
            does not include personal information. We will not use identifiable
            personal information without your consent.
            </p>
        </li>
        </ul>
        <p><a id="3-WILL-YOUR-INFORMATION-BE-SHARED-WITH-ANYONE"></a></p>
        <h3 id="3-will-your-information-be-shared-with-anyone-">
        3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
        </h3>
        <p>
        <em
            ><strong>In Short:</strong> We only share information with your consent, to
            comply with laws, to provide you with services, to protect your rights, or
            to fulfill business obligations.</em
        >
        </p>
        <p>
        We may process or share your data that we hold based on the following legal
        basis:
        </p>
        <ul>
        <li>
            <p>
            <strong>Consent:</strong> We may process your data if you have given us
            specific consent to use your personal information for a specific purpose.
            </p>
        </li>
        <li>
            <p>
            <strong>Legitimate Interests:</strong> We may process your data when it is
            reasonably necessary to achieve our legitimate business interests.
            </p>
        </li>
        <li>
            <p>
            <strong>Performance of a Contract:</strong> Where we have entered into a
            contract with you, we may process your personal information to fulfill the
            terms of our contract.
            </p>
        </li>
        <li>
            <p>
            <strong>Legal Obligations:</strong> We may disclose your information where
            we are legally required to do so in order to comply with applicable law,
            governmental requests, a judicial proceeding, court order, or legal
            process, such as in response to a court order or a subpoena (including in
            response to public authorities to meet national security or law
            enforcement requirements).
            </p>
        </li>
        <li>
            <p>
            <strong>Vital Interests:</strong> We may disclose your information where
            we believe it is necessary to investigate, prevent, or take action
            regarding potential violations of our policies, suspected fraud,
            situations involving potential threats to the safety of any person and
            illegal activities, or as evidence in litigation in which we are involved.
            </p>
        </li>
        </ul>
        <p>
        More specifically, we may need to process your data or share your personal
        information in the following situations:
        </p>
        <ul>
        <li>
            <p>
            <strong>Business Transfers.</strong> We may share or transfer your
            information in connection with, or during negotiations of, any merger,
            sale of company assets, financing, or acquisition of all or a portion of
            our business to another company.
            </p>
        </li>
        <li>
            <p>
            <strong>Business Partners.</strong> We may share your information with our
            business partners to offer you certain products, services or promotions.
            </p>
        </li>
        </ul>
        <p><a id="4-DO-WE-USE-COOKIES-AND-OTHER-TRACKING-TECHNOLOGIES"></a></p>
        <h3 id="4-do-we-use-cookies-and-other-tracking-technologies-">
        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </h3>
        <p>
        <em
            ><strong>In Short:</strong> We may use cookies and other tracking
            technologies to collect and store your information.</em
        >
        </p>
        <p>
        We may use cookies and similar tracking technologies (like web beacons and
        pixels) to access or store information. Specific information about how we use
        such technologies and how you can refuse certain cookies is set out in our
        Cookie Notice.
        </p>
        <p><a id="5-HOW-DO-WE-HANDLE-YOUR-SOCIAL-LOGINS"></a></p>
        <h3 id="5-how-do-we-handle-your-social-logins-">
        5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </h3>
        <p>
        <em
            ><strong>In Short:</strong> If you choose to register or log in to our
            services using a social media account, we may have access to certain
            information about you.</em
        >
        </p>
        <p>
        Our Services offers you the ability to register and login using your
        third-party social media account details (like your Facebook or Twitter
        logins). Where you choose to do this, we will receive certain profile
        information about you from your social media provider. The profile information
        we receive may vary depending on the social media provider concerned, but will
        often include your name, email address, friends list, profile picture as well
        as other information you choose to make public on such social media platform.
        </p>
        <p>
        We will use the information we receive only for the purposes that are
        described in this privacy notice or that are otherwise made clear to you on
        the relevant Services. Please note that we do not control, and are not
        responsible for, other uses of your personal information by your third-party
        social media provider. We recommend that you review their privacy notice to
        understand how they collect, use and share your personal information, and how
        you can set your privacy preferences on their sites and apps.
        </p>
        <p><a id="6-IS-YOUR-INFORMATION-TRANSFERRED-INTERNATIONALL"></a></p>
        <h3 id="6-is-your-information-transferred-internationally-">
        6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
        </h3>
        <p>
        <em
            ><strong>In Short:</strong> We may transfer, store, and process your
            information in countries other than your own.</em
        >
        </p>
        <p>
        If you are accessing our Services from outside, please be aware that your
        information may be transferred to, stored, and processed by us in our
        facilities and by those third parties with whom we may share your personal
        information (see &quot;<a
            href="#3-WILL-YOUR-INFORMATION-BE-SHARED-WITH-ANYONE?"
            >WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a
        >&quot; above), in and other countrie.
        </p>
        <p>
        If you are a resident in the European Economic Area (EEA) or United Kingdom
        (UK), then these countries may not necessarily have data protection laws or
        other similar laws as comprehensive as those in your country. We will however
        take all necessary measures to protect your personal information in accordance
        with this privacy notice and applicable law.
        </p>
        <p><a id="7-WHAT-IS-OUR-STANCE-ON-THIRD-PARTY-WEBSITES"></a></p>
        <h3 id="7-what-is-our-stance-on-third-party-websites-">
        7. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
        </h3>
        <p>
        <em
            ><strong>In Short:</strong> We are not responsible for the safety of any
            information that you share with third-party providers who advertise, but are
            not affiliated with, our Website.</em
        >
        </p>
        <p>
        The Services may contain advertisements from third parties that are not
        affiliated with us and which may link to other websites, online services or
        mobile applications. We cannot guarantee the safety and privacy of data you
        provide to any third parties. Any data collected by third parties is not
        covered by this privacy notice. We are not responsible for the content or
        privacy and security practices and policies of any third parties, including
        other websites, services or applications that may be linked to or from the
        Services. You should review the policies of such third parties and contact
        them directly to respond to your questions.
        </p>
        <p><a id="8-HOW-LONG-DO-WE-KEEP-YOUR-INFORMATION"></a></p>
        <h3 id="8-how-long-do-we-keep-your-information-">
        8. HOW LONG DO WE KEEP YOUR INFORMATION?
        </h3>
        <p>
        <em
            ><strong>In Short:</strong> We keep your information for as long as
            necessary to fulfill the purposes outlined in this privacy notice unless
            otherwise required by law.</em
        >
        </p>
        <p>
        We will only keep your personal information for as long as it is necessary for
        the purposes set out in this privacy notice, unless a longer retention period
        is required or permitted by law (such as tax, accounting or other legal
        requirements). No purpose in this notice will require us keeping your personal
        information for longer than nine (9)months past the termination of the
        user&#39;s account.
        </p>
        <p>
        When we have no ongoing legitimate business need to process your personal
        information, we will either delete or anonymize such information, or, if this
        is not possible (for example, because your personal information has been
        stored in backup archives), then we will securely store your personal
        information and isolate it from any further processing until deletion is
        possible.
        </p>
        <p><a id="9-HOW-DO-WE-KEEP-YOUR-INFORMATION-SAFE"></a></p>
        <h3 id="9-how-do-we-keep-your-information-safe-">
        9. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </h3>
        <p>
        <em
            ><strong>In Short:</strong> We aim to protect your personal information
            through a system of organizational and technical security measures.</em
        >
        </p>
        <p>
        We have implemented appropriate technical and security measures designed to
        protect the security of any personal information we process. However, despite
        our safeguards and efforts to secure your information, no electronic
        transmission over the Internet or information storage technology can be
        guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
        cybercriminals, or other unauthorized third parties will not be able to defeat
        our security, and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our Services is
        at your own risk. You should only access the Services within a secure
        environment.
        </p>
        <p><a id="10-DO-WE-COLLECT-INFORMATION-FROM-MINORS"></a></p>
        <h3 id="10-do-we-collect-information-from-minors-">
        10. DO WE COLLECT INFORMATION FROM MINORS?
        </h3>
        <p>
        <em
            ><strong>In Short:</strong> We do not knowingly collect data from or market
            to children under 18 years of age.</em
        >
        </p>
        <p>
        We do not knowingly solicit data from or market to children under 18 years of
        age. By using the Services, you represent that you are at least 18 or that you
        are the parent or guardian of such a minor and consent to such minor
        dependent&#39;s use of the Services. If we learn that personal information
        from users less than 18 years of age has been collected, we will deactivate
        the account and take reasonable measures to promptly delete such data from our
        records. If you become aware of any data we may have collected from children
        under age 18, please contact us at information@stockjabber.com.
        </p>
        <p><a id="11-WHAT-ARE-YOUR-PRIVACY-RIGHTS"></a></p>
        <h3 id="11-what-are-your-privacy-rights-">11. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
        <p>
        <em
            ><strong>In Short:</strong> You may review, change, or terminate your
            account at any time.</em
        >
        </p>
        <p>
        If you have questions or comments about your privacy rights, you may email us
        at information@stockjabber.com.
        </p>
        <p><strong>Account Information</strong></p>
        <p>
        If you would at any time like to review or change the information in your
        account or terminate your account, you can:
        </p>
        <ul>
        <li>Log in to your Profile page and update your user account.</li>
        </ul>
        <p>
        Upon your request to terminate your account, we will deactivate or delete your
        account and information from our active databases. However, we may retain some
        information in our files to prevent fraud, troubleshoot problems, assist with
        any investigations, enforce our Terms of Use and/or comply with applicable
        legal requirements.
        </p>
        <p>
        <strong>Cookies and similar technologies:</strong> Most Web browsers are set
        to accept cookies by default. If you prefer, you can usually choose to set
        your browser to remove cookies and to reject cookies. If you choose to remove
        cookies or reject cookies, this could affect certain features or services of
        our Services. To opt-out of interest-based advertising by advertisers on our
        Services visit
        <a href="http://www.aboutads.info/choices/"
            >http://www.aboutads.info/choices/</a
        >.
        </p>
        <p>
        <strong>Opting out of email marketing:</strong> You can unsubscribe from our
        marketing email list at any time by clicking on the unsubscribe link in the
        emails that we send or by contacting us using the details provided below. You
        will then be removed from the marketing email list — however, we may still
        communicate with you, for example to send you service-related emails that are
        necessary for the administration and use of your account, to respond to
        service requests, or for other non-marketing purposes. To otherwise opt-out,
        you may:
        </p>
        <ul>
        <li>Access your account settings or Profile and update your preferences.</li>
        </ul>
        <p><a id="12-CONTROLS-FOR-DO-NOT-TRACK-FEATURE"></a></p>
        <h3 id="12-controls-for-do-not-track-features">
        12. CONTROLS FOR DO-NOT-TRACK FEATURES
        </h3>
        <p>
        Most web browsers and some mobile operating systems and mobile applications
        include a Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate
        to signal your privacy preference not to have data about your online browsing
        activities monitored and collected. At this stage no uniform technology
        standard for recognizing and implementing DNT signals has been finalized. As
        such, we do not currently respond to DNT browser signals or any other
        mechanism that automatically communicates your choice not to be tracked
        online. If a standard for online tracking is adopted that we must follow in
        the future, we will inform you about that practice in a revised version of
        this privacy notice.
        </p>
        <p><a id="13-DO-CALIFORNIA-RESIDENTS-HAVE-SPECIFIC-PRIVACY-RIGHTS"></a></p>
        <h3 id="13-do-california-residents-have-specific-privacy-rights-">
        13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h3>
        <p>
        <em
            ><strong>In Short:</strong> Yes, if you are a resident of California, you
            are granted specific rights regarding access to your personal
            information.</em
        >
        </p>
        <p>
        California Civil Code Section 1798.83, also known as the &quot;Shine The
        Light&quot; law, permits our users who are California residents to request and
        obtain from us, once a year and free of charge, information about categories
        of personal information (if any) we disclosed to third parties for direct
        marketing purposes and the names and addresses of all third parties with which
        we shared personal information in the immediately preceding calendar year. If
        you are a California resident and would like to make such a request, please
        submit your request in writing to us using the contact information provided
        below.
        </p>
        <p>
        If you are under 18 years of age, reside in California, and have a registered
        account with a Service, you have the right to request removal of unwanted data
        that you publicly post on the Services. To request removal of such data,
        please contact us using the contact information provided below, and include
        the email address associated with your account and a statement that you reside
        in California. We will make sure the data is not publicly displayed on the
        Services, but please be aware that the data may not be completely or
        comprehensively removed from all our systems (e.g. backups, etc.).
        </p>
        <p><a id="14-DO-WE-MAKE-UPDATES-TO-THIS-NOTICE"></a></p>
        <h3 id="14-do-we-make-updates-to-this-notice-">
        14. DO WE MAKE UPDATES TO THIS NOTICE?
        </h3>
        <p>
        <em
            ><strong>In Short:</strong> Yes, we will update this notice as necessary to
            stay compliant with relevant laws.</em
        >
        </p>
        <p>
        We may update this privacy notice from time to time. The updated version will
        be indicated by an updated &quot;Revised&quot; date and the updated version
        will be effective as soon as it is accessible. If we make material changes to
        this privacy notice, we may notify you either by prominently posting a notice
        of such changes or by directly sending you a notification. We encourage you to
        review this privacy notice frequently to be informed of how we are protecting
        your information.
        </p>
        <p><a id="15-HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE"></a></p>
        <h3 id="15-how-can-you-contact-us-about-this-notice-">
        15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </h3>
        <p>
        If you have questions or comments about this notice, you may email us at
        <a href="mailto:information@stockjabber.com">information@stockjabber.com</a>:
        </p>
        <p><strong>Datacent Owner of StockJabber.com</strong></p>

        </div>
        </>
    )
}


export default Privacy
